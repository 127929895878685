import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import { darkTheme, lightTheme } from './Themes';
import { NOTES, NOTE_TO_INDEX, SCALES, isNoteInScale, MAJOR_MODES, MELODIC_MINOR_MODES, HARMONIC_MINOR_MODES, HARMONIC_MAJOR_MODES, PENTATONIC_MODES } from './Controls';
import { NOTE_COLORS } from './Controls';

// Utility function to clamp a value between min and max
const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

// Add these constants at the top of the file
const INLAY_POSITIONS = [
  [2, 3], [4, 5], [6, 7], [8, 9], [11, 12],
  [14, 15], [16, 17], [18, 19], [20, 21], [23, 24]
];

const MIN_DENSITY = 1;
const MAX_DENSITY = 25;

// Add this constant at the top of the file
const RAINBOW_COLORS = [
    '#FF0000', // Red (Root) - Unchanged at start
    '#FF009F', // Rose
    '#CC00FF', // Magenta
    '#0040FF', // Azure-Blue
    '#007FFF', // Azure
    '#00FF00', // Green
    '#9FFF00', // Yellow-Green
    '#FFFF00', // Yellow
    '#FFBF00', // Orange-Yellow
    '#FF9F00', // Orange-Yellow
    '#FF7F00', // Orange
    '#FF5F00', // Red-Orange
];

// Add this helper function
const getNoteColor = (note, colorMode = 'Default', theme, scaleRoot, nodeDisplayMode, nodeTextMode) => {
  if (!note) return null;
  
  const noteIndex = NOTE_TO_INDEX[note];
  if (noteIndex === undefined) return null;
  
  // Only make root note red if we're not in interval mode with hidden text
  if ((colorMode === 'Default' || colorMode === 'Piano') && 
      note === scaleRoot && 
      !(nodeDisplayMode === NODE_DISPLAY_MODES.INTERVALS && nodeTextMode === NODE_TEXT_MODES.HIDE_ALL)) {
    return '#FF0000'; // Red for root note
  }
  
  if (colorMode === 'Piano') {
    // Check if the note is an accidental
    const isAccidental = Array.isArray(NOTES[noteIndex]);
    return isAccidental ? theme.diagramBg : theme.nodeColor;
  }
  
  if (colorMode === 'Default') {
    return theme.nodeColor;
  }
  
  // For Rainbow mode, shift the colors so C is red (index 0)
  const shiftedIndex = (noteIndex - 3 + 12) % 12; // Shift by 3 since C is at index 3
  return RAINBOW_COLORS[shiftedIndex];
};

// Move formatNoteSymbol outside the component and export it
export const formatNoteSymbol = (note, accidentalStyle = 'sharp') => {
  if (!note) return '';
  
  // Check if the note has an accidental (is an array in NOTES)
  const isAccidental = Array.isArray(NOTES[NOTE_TO_INDEX[note]]);
  
  // Format normally
  return note
    .replace('#', '♯')
    .replace('b', '♭')
    .replace('♭', '♭');
};

// Add this helper function near the top of the file
const getNoteAtFret = (rootNote, fret, useFlats = false) => {
  const rootIndex = NOTE_TO_INDEX[rootNote];
  if (rootIndex === undefined) return '';
  
  const noteIndex = (rootIndex + fret) % 12;
  const note = NOTES[noteIndex];
  
  // If the note has both sharp and flat variants, select based on preference
  if (Array.isArray(note)) {
    return note[useFlats ? 1 : 0];  // Use 0 for sharp (#), 1 for flat (b)
  }
  
  return note;
};

// Add this near the top of the file with other constants
export const NODE_TEXT_MODES = {
  HIDE_ALL: 'HIDE_ALL',
  SHOW_ALL: 'SHOW_ALL'
};

// Add this near the top of the file with other constants
export const NODE_DISPLAY_MODES = {
  NOTES: 'notes',
  INTERVALS: 'intervals'
};

// Add this helper function near the top with other utility functions
const getIntervalFromRoot = (rootNote, note) => {
  if (!rootNote || !note) return null;
  const rootIndex = NOTE_TO_INDEX[rootNote];
  const noteIndex = NOTE_TO_INDEX[note];
  if (rootIndex === undefined || noteIndex === undefined) return null;
  
  const interval = (noteIndex - rootIndex + 12) % 12;
  return {
    0: 'R',
    1: '♭2',
    2: '2',
    3: '♭3',
    4: '3',
    5: '4',
    6: '♭5',
    7: '5',
    8: '♭6',
    9: '6',
    10: '♭7',
    11: '7'
  }[interval];
};

// Add this helper function to get interval index
const getIntervalIndex = (note, root) => {
  if (!note || !root) return null;
  const rootIndex = NOTE_TO_INDEX[root];
  const noteIndex = NOTE_TO_INDEX[note];
  if (rootIndex === undefined || noteIndex === undefined) return null;
  return (noteIndex - rootIndex + 12) % 12;
};

const Diagram = forwardRef(({
  unit,
  strings = 6,
  showNodes = false,
  height,
  isDarkMode,
  stringSizeBase = 1,
  isInfiniteFrets,
  showInlays = false,
  isVertical = false,
  onViewboxChange,
  fretDensity = 3.5,
  onFretDensityChange,
  isInverted = false,
  tuning = ['E', 'A', 'D', 'G', 'B', 'E'],
  useFlats = false,
  accidentalStyle = 'sharp',
  onTuningChange,
  showBounds = false,
  titleAreaRef,
  selectedRoot = null,
  selectedScale = null,
  onNodeClick,
  noteColors = {},
  scaleRoot = null,
  rootIndicator = 'circle',
  colorMode = 'Default',
  currentPreset = null,
  showNodeText = true, // Add this prop with default value
  nodeTextMode = NODE_TEXT_MODES.SHOW_ALL, // Update default value
  nodeDisplayMode = NODE_DISPLAY_MODES.NOTES, // Add default value
}, ref) => {
  const containerRef = useRef(null);
  const nodeRadius = unit * 0.75;
  const PADDING = {
    top: isVertical ? unit * 0.5 : unit * 2,
    right: unit * 1,
    bottom: isVertical 
      ? unit * 2
      : isInfiniteFrets ? unit * 2 : unit * 3.5,
    left: unit * 2.5,
    title: unit * 4
  };

  // Define fret widths
  const regularFretWidth = stringSizeBase * 4;
  const fret0Width = regularFretWidth * 3; // Make fret 0 three times wider

  // Move these calculations here so they can be reused
  const bottomStringThickness = stringSizeBase + ((strings - 1) * 1); // Thickest string
  const stringPadding = (bottomStringThickness * 0.5);

  // Update calculateDimensions to use the moved calculations
  const calculateDimensions = (containerWidth, stringCount, unitSize, baseStringSize) => {
    const stringSpacing = unitSize * 0.75 * 3; // nodeRadius * 3
    const totalStringHeight = isVertical 
      ? unitSize * 15  // Fixed height in vertical mode
      : stringSpacing * (stringCount - 1);
    
    return {
      width: containerWidth,
      height: totalStringHeight + stringPadding,
    };
  };

  // Initial dimensions state
  const [dimensions, setDimensions] = useState(() => 
    calculateDimensions(window.innerWidth, strings, unit, stringSizeBase)
  );

  // Update stringLinePositions calculation
  const stringLinePositions = Array.from({ length: strings }, (_, i) => {
    const stringSpacing = nodeRadius * 3;
    
    if (strings === 1) {
      return dimensions.height / 2;
    } else {
      // Calculate base position
      const basePosition = i * stringSpacing;
      
      // Calculate string thickness based on position
      const stringThickness = stringSizeBase + (isInverted ? i : (strings - 1 - i)) * 1;
      
      // In vertical mode, first offset all strings left by half of thickest string thickness,
      // then adjust individual positions based on their own thickness
      if (isVertical) {
        const thickestStringOffset = bottomStringThickness / 2;
        
        if (isInverted) {
          // When inverted, thickest string (last) moves right, thinnest (first) moves left
          return basePosition + thickestStringOffset - (stringThickness / 2);
        } else {
          // When not inverted, thickest string (first) moves right, thinnest (last) moves left
          return basePosition - thickestStringOffset + (stringThickness / 2);
        }
      }
      
      // For horizontal mode, maintain existing behavior
      if (isInverted) {
        return basePosition + bottomStringThickness / 2;
      }
      
      return basePosition;
    }
  });

  const [lines, setLines] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const lastMouseX = useRef(0);
  const [targetOffset, setTargetOffset] = useState(0);
  const animationFrameRef = useRef(null);
  const [intersections, setIntersections] = useState([]);

  const theme = isDarkMode ? darkTheme : lightTheme;

  // Add state for fretDensity
  const [currentFretDensity, setCurrentFretDensity] = useState(fretDensity);

  // Add state for pinch-zoom
  const [isPinching, setIsPinching] = useState(false);
  const [lastPinchDistance, setLastPinchDistance] = useState(null);

  // Add this constant at the top of the component
  const MIN_VISIBLE_FRETS = 3;  // Minimum number of frets to show


  // Calculate horizontal line positions
  const getLineWidth = (index, totalLines) => {
    // Special case for 5-string banjo
    if (currentPreset?.instrument === "Banjo" && currentPreset?.strings === 5) {
      // Make the 5th string (index 4) the same width as the thinnest string
      if (index === 4) {
        return stringSizeBase + (isInverted ? (totalLines - 1) : 0) * 1;
      }
    }
    
    // Default behavior for all other cases
    const adjustedIndex = isInverted ? (totalLines - 1 - index) : index;
    return stringSizeBase + adjustedIndex * 1;
  };

  // Update the useEffect that handles dimension updates
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const newDimensions = calculateDimensions(rect.width, strings, unit, stringSizeBase);

        if (
          newDimensions.width !== dimensions.width ||
          newDimensions.height !== dimensions.height
        ) {
          setDimensions(newDimensions);
        }

        onViewboxChange?.({ unit });
      }
    };

    updateDimensions();

    const resizeObserver = new ResizeObserver(updateDimensions);
    window.addEventListener('resize', updateDimensions);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, [height, dimensions.width, dimensions.height, unit, onViewboxChange, strings, stringSizeBase]);

  // Clamp scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Replace the getMaxScroll and related parts of generateFrets
  const calculateVisibleFrets = () => {
    // Use the dimension in the direction of the fretboard
    const availableSpace = isVertical 
      ? dimensions.height
      : dimensions.width;

    const numFrets = Math.max(
      MIN_VISIBLE_FRETS,
      Math.ceil(availableSpace / (unit * currentFretDensity))
    );

    // Always limit to 24 visible frets maximum, regardless of mode
    return Math.min(numFrets, 24);
  };

  const getMaxScroll = () => {
    if (isInfiniteFrets) {
      return null;
    }
    const visibleFrets = calculateVisibleFrets();
    return Math.max(0, 24 - visibleFrets);
  };

  // Update generateFrets to use proper dimensions
  const generateFrets = (offset) => {
    const positions = [];
    const numLines = calculateVisibleFrets();
    
    // Calculate border widths
    const isFret0Border = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
    const nutWidth = isFret0Border ? fret0Width : regularFretWidth;
    const bridgeWidth = regularFretWidth;
    
    // Subtract half of both border widths from available space
    const availableSpace = isVertical 
      ? dimensions.height - (nutWidth / 2) - (bridgeWidth / 2)
      : dimensions.width - (nutWidth / 2) - (bridgeWidth / 2);

    // Add extra spacing for fret 0 nodes only when nodes are visible
    const isFret0Visible = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
    const extraNutSpacing = isFret0Visible && showNodes ? nodeRadius * 2 : 0;

    const FRET_CONSTANT = Math.pow(2, 1/12);
    const totalFrets = numLines;
    const maxScaleFactor = Math.pow(FRET_CONSTANT, -totalFrets);

    positions.length = 0;
    // Start at half the nut width
    positions.push(nutWidth / 2 + extraNutSpacing);

    const isFret0Transitioning = !isInfiniteFrets && scrollPosition < 1;

    if (isFret0Transitioning) {
      const transitionProgress = 1 - scrollPosition;
      const currentFret0Width = showNodes 
        ? (fret0Width * transitionProgress + regularFretWidth * (1 - transitionProgress))
        : regularFretWidth;
      
      const fret0ReferencePoint = regularFretWidth + extraNutSpacing;
      
      for (let i = 1; i <= totalFrets; i++) {
        const fretPosition = i - offset;
        const scaleFactor = Math.pow(FRET_CONSTANT, -fretPosition);
        const normalizedScale = 1 - (scaleFactor - 1) / (maxScaleFactor - 1);
        const x = fret0ReferencePoint + (availableSpace - fret0ReferencePoint) * (1 - normalizedScale);

        if (x >= 0 && x <= availableSpace) {
          positions.push(x + nutWidth / 2); // Add half nut width to all positions
        }
      }
    } else {
      for (let i = 0; i <= totalFrets; i++) {
        const fretPosition = i - offset;
        const scaleFactor = Math.pow(FRET_CONSTANT, -fretPosition);
        const normalizedScale = 1 - (scaleFactor - 1) / (maxScaleFactor - 1);
        const x = extraNutSpacing + (availableSpace - extraNutSpacing) * (1 - normalizedScale);

        if (x >= 0 && x <= availableSpace) {
          positions.push(x + nutWidth / 2); // Add half nut width to all positions
        }
      }
    }

    // Add the final position (total width including border widths)
    if (positions[positions.length - 1] !== availableSpace + nutWidth / 2) {
      positions.push(availableSpace + nutWidth / 2);
    }

    return [...new Set(positions)].sort((a, b) => a - b);
  };

  // Update the mode changes effect
  useEffect(() => {
    // Reset all position-related state when switching modes
    setScrollPosition(0);
    setCurrentOffset(0);
    setTargetOffset(0);

    // Force recalculation of lines and fret numbers
    // Wait for next frame to ensure dimensions are updated
    requestAnimationFrame(() => {
      const numFrets = calculateVisibleFrets();
      const initialFrets = Array.from({ length: numFrets }, (_, i) => i);
      setVisibleFrets(initialFrets);
      
      const newLines = generateFrets(0);
      setLines(newLines);
    });
  }, [isVertical, dimensions.width, dimensions.height]);

  // Add these new states near the top with other state declarations
  const [dragStartPosition, setDragStartPosition] = useState(null);
  const [hasGenuinelyDragged, setHasGenuinelyDragged] = useState(false);
  const DRAG_THRESHOLD = 5; // pixels

  // Update handleMouseDown
  const handleMouseDown = (e) => {
    setIsDragging(true);
    lastMouseX.current = isVertical ? e.clientY : e.clientX;
    setDragStartPosition({
      x: e.clientX,
      y: e.clientY
    });
    setHasGenuinelyDragged(false);
  };

  // Add these state variables at the top of the component
  const [visibleFrets, setVisibleFrets] = useState([]);

  // Add a new state to track if we're currently snapping
  const [isSnapping, setIsSnapping] = useState(false);

  // Modify the handleMouseMove function
  const handleMouseMove = (e) => {
    if (!isDragging) return;

    // Check if we've exceeded the drag threshold
    if (dragStartPosition && !hasGenuinelyDragged) {
      const deltaX = Math.abs(e.clientX - dragStartPosition.x);
      const deltaY = Math.abs(e.clientY - dragStartPosition.y);
      if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
        setHasGenuinelyDragged(true);
      }
    }

    const delta = isVertical ? e.clientY - lastMouseX.current : e.clientX - lastMouseX.current;
    const sensitivity = 0.02;

    let newScrollPosition = scrollPosition - delta * sensitivity;
    
    if (!isInfiniteFrets) {
      const maxScroll = getMaxScroll();
      newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
    }

    setScrollPosition(newScrollPosition);
    setCurrentOffset(newScrollPosition % 1);
    setTargetOffset(newScrollPosition % 1);

    lastMouseX.current = isVertical ? e.clientY : e.clientX;
  };

  // Modify handleMouseUp to remove clamping in infinite mode
  const handleMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);
    setDragStartPosition(null);
    setIsSnapping(true);

    const nearestFret = Math.round(scrollPosition);
    
    if (!isInfiniteFrets) {
      const maxScroll = getMaxScroll();
      const limitedFret = clamp(nearestFret, 0, maxScroll);
      
      // Calculate the number of visible frets before updating position
      const numFrets = calculateVisibleFrets();
      const newVisibleFrets = Array.from(
        { length: numFrets + 1 }, // Add +1 to ensure we include the last fret during transition
        (_, i) => i + limitedFret
      ).filter(fret => fret >= 0 && fret <= 24);
      
      setVisibleFrets(newVisibleFrets);
      setScrollPosition(limitedFret);
    } else {
      setScrollPosition(nearestFret);
    }
    
    setTargetOffset(0);
  };

  // Add this helper function at the top level of the component
  const getShortestDistance = (current, target) => {
    const direct = target - current;
    const wrapped = direct > 0 ? direct - 1 : direct + 1;
    return Math.abs(direct) < Math.abs(wrapped) ? direct : wrapped;
  };

  // Update the animation effect
  useEffect(() => {
    if (targetOffset === currentOffset) {
      setIsSnapping(false); // Animation complete
      return;
    }

    const animate = () => {
      setCurrentOffset((current) => {
        const diff = getShortestDistance(current, targetOffset);
        const newOffset = current + diff * 0.1;

        if (Math.abs(diff) < 0.001) {
          setIsSnapping(false); // Animation complete
          return targetOffset;
        }

        return newOffset >= 1
          ? newOffset - 1
          : newOffset < 0
          ? newOffset + 1
          : newOffset;
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [targetOffset]);

  // Modify the initial snap effect
  useEffect(() => {
    if (dimensions.width > 0) {
      // Start at exactly 0 offset
      setTargetOffset(0);
      setCurrentOffset(0);
      setScrollPosition(0);
    }
  }, [dimensions.width]);

  // Update lines when offset changes
  useEffect(() => {
    const newLines = generateFrets(currentOffset);
    if (JSON.stringify(newLines) !== JSON.stringify(lines)) {
      setLines(newLines);

      // Calculate base fret number
      let firstVisibleFret = Math.floor(scrollPosition);

      // If we're snapping and moving right (current > target), adjust the fret number
      // based on how far through the animation we are
      if (isSnapping && currentOffset > targetOffset && currentOffset > 0.5) {
        firstVisibleFret -= 1;
      }

      const numVisibleFrets = newLines.length;
      
      // Adjust visible frets calculation for fixed mode
      let newVisibleFrets;
      if (!isInfiniteFrets && firstVisibleFret + numVisibleFrets > 24) {
        // If we would exceed fret 24, adjust the starting fret
        const maxStartingFret = 24 - numVisibleFrets + 1;
        firstVisibleFret = Math.max(0, maxStartingFret);
        newVisibleFrets = Array.from(
          { length: numVisibleFrets + (isSnapping ? 1 : 0) }, // Add extra fret during snapping
          (_, i) => firstVisibleFret + i
        ).filter(fret => fret >= 0 && fret <= 24);
      } else {
        newVisibleFrets = Array.from(
          { length: numVisibleFrets + (isSnapping ? 1 : 0) }, // Add extra fret during snapping
          (_, i) => firstVisibleFret + i
        ).filter(fret => fret >= 0 && fret <= 24);
      }

      setVisibleFrets(newVisibleFrets);
    }
  }, [
    currentOffset,
    dimensions.width,
    scrollPosition,
    isSnapping,
    targetOffset,
    currentFretDensity,
    isInfiniteFrets,
    showNodes
  ]);

  // Add touch event handlers
  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent default touch behavior
    if (e.touches.length === 2) {
      setIsPinching(true);
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      setLastPinchDistance(distance);
      
      // Set initial position for two-finger drag
      setTouchpadGestureStart({
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2
      });
    } else if (e.touches.length === 1) {
      setIsDragging(true);
      const touch = e.touches[0];
      // Use clientY for vertical mode, clientX for horizontal
      lastMouseX.current = isVertical ? touch.clientY : touch.clientX;
    }
  };

  // Add touchpad gesture state tracking
  const [touchpadGestureStart, setTouchpadGestureStart] = useState(null);

  // Modify handleTouchMove to handle touchpad gestures
  const handleTouchMove = (e) => {
    if (isPinching && e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      
      if (touchpadGestureStart) {
        // Calculate the average position of both fingers
        const currentY = (touch1.clientY + touch2.clientY) / 2;
        const currentX = (touch1.clientX + touch2.clientX) / 2;
        
        // Use vertical or horizontal movement based on orientation
        const delta = isVertical 
          ? currentY - touchpadGestureStart.y
          : currentX - touchpadGestureStart.x;
        
        const sensitivity = 0.02;
        let newScrollPosition = scrollPosition - delta * sensitivity;
        
        if (!isInfiniteFrets) {
          const maxScroll = getMaxScroll();
          newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
        }

        setScrollPosition(newScrollPosition);
        setCurrentOffset(newScrollPosition % 1);
        setTargetOffset(newScrollPosition % 1);

        // Update gesture start position
        setTouchpadGestureStart({ x: currentX, y: currentY });
      }
      
      // Handle pinch zoom distance calculation
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      
      if (lastPinchDistance !== null) {
        const deltaDistance = distance - lastPinchDistance;
        const sensitivity = 0.02;
        
        // **Inverted Density Adjustment**
        const logScale = Math.log(currentFretDensity + 1) / Math.log(MAX_DENSITY + 1);
        const scaledSensitivity = sensitivity * (1 + logScale * 2);
        
        // **Invert the direction of density change**
        const newDensity = clamp(
          currentFretDensity + deltaDistance * scaledSensitivity, // Changed from '-' to '+'
          MIN_DENSITY,
          MAX_DENSITY
        );
        setCurrentFretDensity(newDensity);
        onFretDensityChange?.(newDensity);
      }
      
      setLastPinchDistance(distance);
    } else if (isDragging && e.touches.length === 1) {
      const touch = e.touches[0];
      const currentPosition = isVertical ? touch.clientY : touch.clientX;
      const delta = currentPosition - lastMouseX.current;
      const sensitivity = 0.02;

      let newScrollPosition = scrollPosition - delta * sensitivity;
      
      if (!isInfiniteFrets) {
        const maxScroll = getMaxScroll();
        newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
      }

      setScrollPosition(newScrollPosition);
      setCurrentOffset(newScrollPosition % 1);
      setTargetOffset(newScrollPosition % 1);

      lastMouseX.current = currentPosition;
    }
  };

  const handleTouchEnd = () => {
    if (isPinching) {
      setIsPinching(false);
      setLastPinchDistance(null);
    }
    handleMouseUp();
  };

  const handleTouchCancel = () => {
    if (isPinching) {
      setIsPinching(false);
      setLastPinchDistance(null);
    }
    handleMouseUp();
  };

  // Update the helper function to handle both left and right scales
  const calculateNodeScale = (lines, position) => {
    if (lines.length < 2) return 1;

    if (position === 'nut') {
      const nutBorder = lines[0];
      const firstFret = lines[1];
      const secondFret = lines[2];

      if (!secondFret) return 1;

      const fretDistance = secondFret - firstFret;
      const distanceFromBorder = firstFret - nutBorder;
      
      const normalizedDistance = distanceFromBorder / fretDistance;
      return normalizedDistance >= 0.5 ? 1 : Math.min(1, Math.max(0, normalizedDistance * 2));
    } else if (position === 'right') {
      const bridgeBorder = lines[lines.length - 1];
      const lastFret = lines[lines.length - 2];
      const secondLastFret = lines[lines.length - 3];

      if (!secondLastFret) return 1;

      const fretDistance = lastFret - secondLastFret;
      // Adjust the distance calculation when fret 0 is visible
      const isFret0Visible = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
      const extraOffset = isFret0Visible ? stringSizeBase * 6 : 0; // Account for wider fret 0
      const distanceFromBorder = bridgeBorder - lastFret + stringSizeBase * 4 + extraOffset;
      return Math.min(1, Math.max(0, distanceFromBorder / fretDistance));
    }

    return 1;
  };

  const nutScale = calculateNodeScale(lines, 'nut');
  const rightScale = calculateNodeScale(lines, 'right');

  // Update the intersection calculation useEffect
  useEffect(() => {
    const newIntersections = [];
    
    // Iterate through strings first, then frets
    stringLinePositions.forEach((y, stringIndex) => {
      lines.forEach((x, fretIndex) => {
        // Skip nodes for fret 0 unless we're showing it in fixed mode
        const isFret0Visible = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
        if (fretIndex === 0 && !isFret0Visible) return;

        // Calculate scale for edge frets
        let scale;
        if (isFret0Visible && fretIndex === 0) {
          scale = 1;
        } else {
          scale = fretIndex === 1
            ? nutScale
            : fretIndex === lines.length - 1
            ? rightScale
            : 1;
        }

        // Calculate base position and width adjustments
        let nodeX = x;
        let fretWidth;
        let positionOffset = 0;

        if (isFret0Visible && fretIndex === 0) {
          // Fret 0 width and position
          fretWidth = fret0Width * scale;
          nodeX = x - fret0Width / 2 - nodeRadius;
        } else {
          // Regular fret width transition for border frets
          const baseWidth = regularFretWidth;
          fretWidth = (fretIndex === 0 || fretIndex === lines.length - 1)
            ? baseWidth * scale
            : baseWidth;
          
          // Adjust position based on width change - reversed directions
          if (fretIndex === 0) {
            positionOffset = -(baseWidth - fretWidth) / 2;
          } else if (fretIndex === lines.length - 1) {
            positionOffset = (baseWidth - fretWidth) / 2;
          }
          
          // Apply position offset and node spacing
          nodeX = x + positionOffset - (nodeRadius + fretWidth / 2) * scale;
        }

        newIntersections.push({
          x: nodeX,
          y,
          radius: nodeRadius * scale,
          opacity: 1,
          stringIndex,
          fretIndex,
          currentFret: visibleFrets[fretIndex]
        });
      });
    });

    if (JSON.stringify(newIntersections) !== JSON.stringify(intersections)) {
      setIntersections(newIntersections);
    }
  }, [
    lines,
    stringLinePositions,
    nodeRadius,
    stringSizeBase,
    nutScale,
    rightScale,
    isInfiniteFrets,
    scrollPosition,
    fret0Width,
    regularFretWidth,
    visibleFrets
  ]);

  // Update the state to track both visibility and animation
  const [nodeVisibility, setNodeVisibility] = useState({
    show: showNodes,
    mounted: showNodes
  });

  // Update the effect to handle both mounting and animation
  useEffect(() => {
    if (showNodes) {
      // Mount all nodes at once
      setNodeVisibility({ show: false, mounted: true });
      
      // Show all nodes together after a brief delay
      const timer = setTimeout(() => {
        setNodeVisibility({ show: true, mounted: true });
      }, 16); // Use a single frame delay
      
      return () => clearTimeout(timer);
    } else {
      // Hide all nodes at once
      setNodeVisibility(prev => ({ ...prev, show: false }));
      
      // Unmount all nodes together after animation
      const timer = setTimeout(() => {
        setNodeVisibility({ show: false, mounted: false });
      }, 200);
      
      return () => clearTimeout(timer);
    }
  }, [showNodes]);

  // Add this function to calculate inlay positions
  const calculateInlays = useCallback(() => {
    if (!showInlays || isInfiniteFrets) return [];

    const stringPositions = stringLinePositions;
    if (stringPositions.length < 2) return [];

    const topY = (stringPositions[0] + stringPositions[1]) / 2;
    const bottomY = (stringPositions[stringPositions.length - 2] + stringPositions[stringPositions.length - 1]) / 2;
    const centerY = (topY + bottomY) / 2;
    const totalHeight = bottomY - topY;

    // Calculate inlays immediately based on current lines
    return INLAY_POSITIONS
      .filter(([start, end]) => {
        const isVisible = visibleFrets.includes(start) && visibleFrets.includes(end);
        return isVisible;
      })
      .map(([start, end]) => {
        const startIndex = visibleFrets.indexOf(start);
        const endIndex = visibleFrets.indexOf(end);
        const x1 = lines[startIndex];
        const x2 = lines[endIndex];
        
        return {
          x: (x1 + x2) / 2,
          y: centerY,
          width: (x2 - x1) * 0.5,
          height: totalHeight
        };
      });
  }, [stringLinePositions, lines, visibleFrets, showInlays, isInfiniteFrets]);

  // Add state for orientation animation
  const [orientationProgress, setOrientationProgress] = useState(isVertical ? 1 : 0);
  
  // Update the orientation animation effect to continuously update fret numbers
  useEffect(() => {
    let lastTime = performance.now();
    let animationFrame;
    const targetProgress = isVertical ? 1 : 0;
    const ANIMATION_SPEED = 4;
    const TARGET_FPS = 120;
    const FRAME_TIME = 120 / TARGET_FPS;
    const COMPLETION_THRESHOLD = 0.001;
    
    const animate = (currentTime) => {
      const deltaTime = currentTime - lastTime;
      
      if (deltaTime >= FRAME_TIME) {
        setOrientationProgress(current => {
          const diff = targetProgress - current;
          if (Math.abs(diff) < COMPLETION_THRESHOLD) {
            return targetProgress;
          }
          
          // Move towards target based on time elapsed
          const step = (ANIMATION_SPEED * deltaTime) / 1000;
          const next = current + Math.sign(diff) * Math.min(Math.abs(diff), step);
          
          // Force fret number recalculation on each animation frame
          const newLines = generateFrets(currentOffset);
          const firstVisibleFret = Math.floor(scrollPosition);
          const numVisibleFrets = newLines.length;
          
          // Include an extra fret during animation
          const extraFret = 1;
          
          let newVisibleFrets;
          if (!isInfiniteFrets && firstVisibleFret + numVisibleFrets > 24) {
            const maxStartingFret = 24 - numVisibleFrets;
            const startFret = Math.max(0, maxStartingFret);
            newVisibleFrets = Array.from(
              { length: numVisibleFrets + extraFret },
              (_, i) => startFret + i
            ).filter(fret => fret >= 0 && fret <= 24);
          } else {
            newVisibleFrets = Array.from(
              { length: numVisibleFrets + extraFret },
              (_, i) => firstVisibleFret + i
            ).filter(fret => fret >= 0 && fret <= 24);
          }
          
          setVisibleFrets(newVisibleFrets);
          
          return next;
        });
        
        lastTime = currentTime;
      }
      
      animationFrame = requestAnimationFrame(animate);
    };
    
    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [isVertical, currentOffset, scrollPosition, isInfiniteFrets]);

  // Add interpolation helper
  const lerp = (start, end, t) => start + (end - start) * t;

  // Modify the SVG render code to interpolate between horizontal and vertical positions
  const isFret0Border = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
  const firstFret = lines[0] || 0;
  const firstFretOffset = isFret0Border ? -(fret0Width / 2) : 0;
  const lastFret = lines[lines.length - 1] || dimensions.width;
  const lastFretOffset = regularFretWidth / 2;  // Add half fret width to extend to right edge

  // Calculate the centering offset once for use across all elements
  const stringSpacing = nodeRadius * 3;
  const totalStringHeight = isVertical
    ? stringSpacing * (Math.max(2, strings) - 1)
    : dimensions.height;
  const verticalCenterOffset = isVertical
    ? (dimensions.height - totalStringHeight) / 2
    : 0;

  // Inside the Diagram component, update the transform in the SVG's <g> element:
  const mainGroupTransform = (() => {
    // Calculate horizontal centering offset for vertical mode
    const horizontalCenter = (dimensions.width - dimensions.height) / 2;
    
    // Interpolate between horizontal and vertical positions
    const xPadding = lerp(
      0, // horizontal mode: no padding
      horizontalCenter, // vertical mode: centered
      orientationProgress
    );
    
    const yPadding = lerp(
      0, // horizontal mode: no padding
      0, // vertical mode: no padding
      orientationProgress
    );

    return `translate(${xPadding}, ${yPadding})`;
  })();

  // Add this effect to notify parent of viewbox dimensions
  useEffect(() => {
    if (onViewboxChange) {
      onViewboxChange({
        width: dimensions.width,
        height: dimensions.height
      });
    }
  }, [dimensions.width, dimensions.height, onViewboxChange]);

  // Add wheel event handler
  const wheelDebounceRef = useRef(null);

  // Modify handleWheel to properly handle both mouse wheel and touchpad gestures
  const handleWheel = (e) => {
    // Only prevent default for pinch zoom
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY;
      const sensitivity = 0.02;
      
      const logScale = Math.log(currentFretDensity + 1) / Math.log(MAX_DENSITY + 1);
      const scaledSensitivity = sensitivity * (1 + logScale * 2);
      
      const newDensity = clamp(
        currentFretDensity - delta * scaledSensitivity,
        MIN_DENSITY,
        MAX_DENSITY
      );
      setCurrentFretDensity(newDensity);
      onFretDensityChange?.(newDensity);
      return;
    }

    // Don't prevent default for regular scrolling
    const isTouchpad = Math.abs(e.deltaY) < 120;

    let delta;
    if (isTouchpad) {
      delta = isVertical ? e.deltaY : e.deltaX;
    } else {
      delta = isVertical ? e.deltaY : (e.shiftKey ? e.deltaY : e.deltaX);
    }

    const sensitivity = isTouchpad ? 0.004 : 0.008;
    let newScrollPosition = scrollPosition + delta * sensitivity;
    
    if (!isInfiniteFrets) {
      const maxScroll = getMaxScroll();
      newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
    }

    setScrollPosition(newScrollPosition);
    setCurrentOffset(newScrollPosition % 1);
    setTargetOffset(newScrollPosition % 1);

    if (wheelDebounceRef.current) {
      clearTimeout(wheelDebounceRef.current);
    }

    wheelDebounceRef.current = setTimeout(() => {
      setIsSnapping(true);
      const nearestFret = Math.round(newScrollPosition);
      
      if (!isInfiniteFrets) {
        const limitedFret = clamp(nearestFret, 0, 24);
        setScrollPosition(limitedFret);
      } else {
        setScrollPosition(nearestFret);
      }
      
      setTargetOffset(0);
    }, 100);
  };

  // Add effect to sync with parent's fretDensity prop
  useEffect(() => {
    setCurrentFretDensity(fretDensity);
  }, [fretDensity]);

  // Add this useEffect to handle zoom prevention
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const preventZoom = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    // Only prevent zoom events, let regular touch/wheel events pass through
    container.addEventListener('wheel', preventZoom, { passive: false });

    return () => {
      container.removeEventListener('wheel', preventZoom);
    };
  }, []);

  // Add this effect to recalculate fret numbers immediately when orientation changes
  useEffect(() => {
    const newLines = generateFrets(currentOffset);
    setLines(newLines);

    let firstVisibleFret = Math.floor(scrollPosition);

    if (isSnapping && currentOffset > targetOffset && currentOffset > 0.5) {
      firstVisibleFret -= 1;
    }

    const numVisibleFrets = newLines.length;
    
    let newVisibleFrets;
    if (!isInfiniteFrets && firstVisibleFret + numVisibleFrets > 24) {
      const maxStartingFret = 24 - numVisibleFrets + 1;
      firstVisibleFret = Math.max(0, maxStartingFret);
      newVisibleFrets = Array.from(
        { length: numVisibleFrets + (isSnapping ? 1 : 0) },
        (_, i) => firstVisibleFret + i
      ).filter(fret => fret >= 0 && fret <= 24);
    } else {
      newVisibleFrets = Array.from(
        { length: numVisibleFrets + (isSnapping ? 1 : 0) },
        (_, i) => firstVisibleFret + i
      ).filter(fret => fret >= 0 && fret <= 24);
    }

    setVisibleFrets(newVisibleFrets);
  }, [isVertical, currentOffset, scrollPosition, isInfiniteFrets, isSnapping, targetOffset]);

  // Add an effect to handle initial snap
  useEffect(() => {
    if (dimensions.width > 0) {
      setIsSnapping(true);
      
      // Ensure we start at exactly 0
      setScrollPosition(0);
      setCurrentOffset(0);
      setTargetOffset(0);

      // Calculate initial visible frets
      const newLines = generateFrets(0);
      const numFrets = calculateVisibleFrets();
      const initialFrets = Array.from(
        { length: numFrets },
        (_, i) => i
      ).filter(fret => fret >= 0 && (!isInfiniteFrets ? fret <= 24 : true));

      setVisibleFrets(initialFrets);
      setLines(newLines);

      // Reset snapping state after animation
      const timer = setTimeout(() => {
        setIsSnapping(false);
      }, 200); // Match with animation duration

      return () => clearTimeout(timer);
    }
  }, [dimensions.width, isInfiniteFrets]); // Only run when width changes or mode changes

  // Add this helper function near the top of the file
  const getLuminance = (hexColor) => {
    // Remove the # if present
    const hex = hexColor.replace('#', '');
    
    // Convert hex to RGB
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;
    
    // Calculate relative luminance using sRGB coefficients
    // Using the formula from WCAG 2.0
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    
    // Return true for dark colors (use white text)
    // The threshold 0.5 can be adjusted if needed
    return luminance < 0.5;
  };

  // Add state to track previous scale notes
  const [previousScaleNotes, setPreviousScaleNotes] = useState(new Set());
  const [nodeAnimationStates, setNodeAnimationStates] = useState(new Map());

  // Update the scale change effect
  useEffect(() => {
    // Get current scale notes all at once
    const currentScaleNotes = new Set();
    const newAnimationStates = new Map();
    const timestamp = Date.now(); // Use same timestamp for all nodes
    
    if (selectedRoot && selectedScale) {
      intersections.forEach(({ stringIndex, currentFret }) => {
        const rootNote = isInverted 
          ? tuning[stringIndex] 
          : tuning[tuning.length - 1 - stringIndex];
        const noteAtFret = !isInfiniteFrets ? getNoteAtFret(rootNote, currentFret, useFlats) : '';
        if (isNoteInScale(noteAtFret, selectedRoot, SCALES[selectedScale])) {
          const noteKey = `${stringIndex}-${currentFret}`;
          currentScaleNotes.add(noteKey);
          // Set all new notes to animate in together
          newAnimationStates.set(noteKey, {
            show: true,
            mounted: true,
            timestamp
          });
        }
      });
    }

    // Handle removed notes
    previousScaleNotes.forEach(noteKey => {
      if (!currentScaleNotes.has(noteKey)) {
        newAnimationStates.set(noteKey, {
          show: false,
          mounted: true,
          timestamp
        });
      }
    });

    // Update all states at once
    setNodeAnimationStates(newAnimationStates);
    setPreviousScaleNotes(currentScaleNotes);
  }, [selectedRoot, selectedScale, intersections, tuning, isInverted, isInfiniteFrets, useFlats]);

  // Update the node visibility effect
  useEffect(() => {
    if (showNodes) {
      // Mount all nodes at once
      setNodeVisibility({ show: false, mounted: true });
      
      // Show all nodes together after a brief delay
      const timer = setTimeout(() => {
        setNodeVisibility({ show: true, mounted: true });
      }, 16); // Use a single frame delay
      
      return () => clearTimeout(timer);
    } else {
      // Hide all nodes at once
      setNodeVisibility(prev => ({ ...prev, show: false }));
      
      // Unmount all nodes together after animation
      const timer = setTimeout(() => {
        setNodeVisibility({ show: false, mounted: false });
      }, 200);
      
      return () => clearTimeout(timer);
    }
  }, [showNodes]);

  // Update the title rendering logic
  const title = (() => {
    if (!selectedScale || selectedScale === 'None') {
      return currentPreset?.instrument || 'Guitar';
    }
    if (selectedScale === 'Chromatic') return 'Chromatic';
    
    // Get the mode name if available
    if (selectedRoot && scaleRoot) {
      const rootIndex = NOTE_TO_INDEX[selectedRoot];
      const scaleRootIndex = NOTE_TO_INDEX[scaleRoot];
      if (rootIndex !== undefined && scaleRootIndex !== undefined) {
        // Calculate the interval between root and scale root
        const interval = (scaleRootIndex - rootIndex + 12) % 12;
        const intervalIndex = SCALES[selectedScale].indexOf(interval);
        
        // Get the appropriate mode name based on the scale
        let modeName = '';
        switch (selectedScale) {
          case 'Major': {
            const mode = MAJOR_MODES[intervalIndex];
            // Handle special cases with parentheses
            if (mode.includes('(')) {
              const baseName = mode.match(/\((.*?)\)/)[1];
              return `${scaleRoot} ${baseName}`;
            }
            modeName = mode;
            break;
          }
          case 'Melodic Minor': {
            const mode = MELODIC_MINOR_MODES[intervalIndex];
            if (mode.includes('(')) {
              const baseName = mode.match(/\((.*?)\)/)[1];
              return `${scaleRoot} ${baseName}`;
            }
            modeName = mode;
            break;
          }
          case 'Harmonic Minor': {
            const mode = HARMONIC_MINOR_MODES[intervalIndex];
            if (mode.includes('(')) {
              const baseName = mode.match(/\((.*?)\)/)[1];
              return `${scaleRoot} ${baseName}`;
            }
            modeName = mode;
            break;
          }
          case 'Harmonic Major': {
            const mode = HARMONIC_MAJOR_MODES[intervalIndex];
            if (mode.includes('(')) {
              const baseName = mode.match(/\((.*?)\)/)[1];
              return `${scaleRoot} ${baseName}`;
            }
            modeName = mode;
            break;
          }
          case 'Pentatonic': {
            const mode = PENTATONIC_MODES[intervalIndex];
            if (mode && mode.includes('(')) {
              const baseName = mode.match(/\((.*?)\)/)[1];
              return `${scaleRoot} ${baseName}`;
            }
            modeName = mode;
            break;
          }
          case 'Blues':
            return `${scaleRoot} Blues`;
          default:
            modeName = '';
        }
        
        // If we have a mode name, use it with the scale root
        if (modeName) {
          return `${scaleRoot} ${modeName}`;
        }
      }
    }
    
    // Default to key + scale name
    const rootText = selectedRoot || '';
    const scaleText = selectedScale === 'Chromatic' ? selectedScale : `${rootText} ${selectedScale}`;
    return scaleText.trim();
  })();

  // Add keyboard navigation handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Skip if user is typing in an input field
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return;

      const sensitivity = 1.0; // Doubled from 0.5 to 1.0
      let delta = 0;

      switch (e.key) {
        case 'ArrowLeft':
          delta = -sensitivity;
          break;
        case 'ArrowRight':
          delta = sensitivity;
          break;
        default:
          return;
      }

      let newScrollPosition = scrollPosition + delta;
      
      if (!isInfiniteFrets) {
        const maxScroll = getMaxScroll();
        newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
      }

      setScrollPosition(newScrollPosition);
      setCurrentOffset(newScrollPosition % 1);
      setTargetOffset(newScrollPosition % 1);

      // Snap to nearest fret after a delay
      if (wheelDebounceRef.current) {
        clearTimeout(wheelDebounceRef.current);
      }

      wheelDebounceRef.current = setTimeout(() => {
        setIsSnapping(true);
        const nearestFret = Math.round(newScrollPosition);
        
        if (!isInfiniteFrets) {
          const limitedFret = clamp(nearestFret, 0, 24);
          setScrollPosition(limitedFret);
        } else {
          setScrollPosition(nearestFret);
        }
        
        setTargetOffset(0);
      }, 100);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [scrollPosition, isInfiniteFrets]);

  // Update the nodes rendering code
  return (
    <div className="w-full h-full flex">
      <div
        ref={containerRef}
        className="w-full h-full overflow-hidden cursor-grab active:cursor-grabbing flex-1"
        style={{
          backgroundColor: theme.diagramBg,
          userSelect: 'none',
          touchAction: 'pan-x pan-y',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          display: 'flex',
          flexDirection: 'column',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        onWheel={handleWheel}
      >
        <svg
          ref={ref}
          viewBox={`${
            -PADDING.left
          } ${
            -PADDING.top - PADDING.title
          } ${
            dimensions.width + PADDING.left + PADDING.right
          } ${
            dimensions.height + PADDING.top + PADDING.bottom + PADDING.title
          }`}
          className="w-full h-full flex-1"
          preserveAspectRatio={isVertical ? "xMidYMid meet" : "xMidYMid meet"}
          style={{ 
            touchAction: 'none',
            display: 'block',
          }}
          onClick={(e) => {
            // Only handle clicks directly on the SVG background
            // and only if we haven't genuinely dragged
            if (e.target === e.currentTarget && !hasGenuinelyDragged) {
              onNodeClick?.(null);
            }
          }}
        >
          {showBounds && (
            <g className="padding-visualization">
              {/* Title padding area */}
              <rect
                x={-PADDING.left}
                y={-PADDING.top - PADDING.title}
                width={dimensions.width + PADDING.left + PADDING.right}
                height={PADDING.title}
                fill="rgba(255, 0, 0, 0.25)"
              />
              {/* Top padding area */}
              <rect
                x={-PADDING.left}
                y={-PADDING.top}
                width={dimensions.width + PADDING.left + PADDING.right}
                height={PADDING.top}
                fill="rgba(0, 255, 0, 0.25)"
              />
              {/* Left padding area */}
              <rect
                x={-PADDING.left}
                y={-PADDING.top}
                width={PADDING.left}
                height={dimensions.height + PADDING.top + PADDING.bottom}
                fill="rgba(0, 0, 255, 0.25)"
              />
              {/* Right padding area */}
              <rect
                x={dimensions.width}
                y={-PADDING.top}
                width={PADDING.right}
                height={dimensions.height + PADDING.top + PADDING.bottom}
                fill="rgba(255, 255, 0, 0.25)"
              />
              {/* Bottom padding area */}
              <rect
                x={-PADDING.left}
                y={dimensions.height}
                width={dimensions.width + PADDING.left + PADDING.right}
                height={PADDING.bottom}
                fill="rgba(128, 0, 128, 0.25)"
              />
            </g>
          )}
          <g>
            <rect
              ref={node => {
                if (node) {
                  const rectHeight = unit * 2; // Fixed height of 2 units
                  const titleCenterY = -PADDING.top - PADDING.title / 2;
                  const rectY = titleCenterY - rectHeight / 2;
                  
                  node.setAttribute('y', rectY);
                  node.setAttribute('height', rectHeight);
                }
              }}
              fill="none"
              stroke={theme.stringColor}
              strokeWidth={stringSizeBase * 3}
              rx={unit * 0.5}
              ry={unit * 0.5}
            />
            <text
              ref={node => {
                if (node) {
                  const bbox = node.getBBox();
                  const paddingX = unit; // Remove conditional, always use default padding
                  
                  const titleCenterY = -PADDING.top - PADDING.title / 2;
                  const textY = titleCenterY;
                  node.setAttribute('y', textY);
                  
                  const rectWidth = bbox.width + (paddingX * 2);
                  const rectX = (dimensions.width - rectWidth) / 2;
                  
                  const rectNode = node.previousSibling;
                  if (rectNode) {
                    rectNode.setAttribute('x', rectX);
                    rectNode.setAttribute('width', rectWidth);

                    // Get the actual screen coordinates of the rect
                    const svgElement = rectNode.ownerSVGElement;
                    const rect = rectNode.getBoundingClientRect();
                    
                    // Set the ref with the screen coordinates
                    if (titleAreaRef) {
                      titleAreaRef.current = {
                        top: rect.top,
                        height: rect.height,
                        center: rect.top + (rect.height / 2)
                      };
                    }
                  }
                }
              }}
              x={dimensions.width / 2}
              textAnchor="middle"
              alignmentBaseline="central"
              fill={theme.stringColor}
              fontSize={unit * 1.25}
              style={{
                fontWeight: 'bold',
                opacity: 1
              }}
            >
              {title}
            </text>
          </g>

          <g transform={mainGroupTransform}>
            
            {/* String Labels */}
            {stringLinePositions.map((y, index) => {
              const isFret0NodesVisible = !isInfiniteFrets && 
                Math.floor(scrollPosition) === 0 && 
                showNodes;
              
              // Calculate horizontal and vertical positions with proper centering
              const horizontalOffset = -PADDING.left * 0.5;
              const verticalOffset = dimensions.height + PADDING.bottom * 0.5;
              
              const verticalX = lerp(
                horizontalOffset, 
                dimensions.height - y - verticalCenterOffset,
                orientationProgress
              );
              const verticalY = lerp(
                y,
                verticalOffset,
                orientationProgress
              );
              
              const stringNumber = isInverted ? strings - index : index + 1;
              const tuningIndex = isInverted ? index : strings - 1 - index;
              const rawStringNote = tuning[tuningIndex] || '';
              const stringNote = formatNoteSymbol(rawStringNote);
              
              return (
                <text
                  key={`label-${index}`}
                  x={verticalX}
                  y={verticalY}
                  textAnchor="middle"
                  alignmentBaseline="central"
                  fill={theme.stringColor}
                  fontSize={nodeRadius * 1}
                >
                  {stringNumber}{!isFret0NodesVisible && stringNote}
                </text>
              );
            })}

            {/* Update horizontal lines */}
            {stringLinePositions.map((y, index) => {
              // Calculate the extra width needed for the nut border
              const nutBorderExtra = isFret0Border ? 0 : regularFretWidth / 2;
              
              const verticalX = lerp(
                firstFret + firstFretOffset - nutBorderExtra,
                dimensions.height - y - verticalCenterOffset,
                orientationProgress
              );
              const verticalY = lerp(
                y,
                firstFret + firstFretOffset - nutBorderExtra,
                orientationProgress
              );
              const verticalX2 = lerp(
                lastFret + lastFretOffset,
                dimensions.height - y - verticalCenterOffset,
                orientationProgress
              );
              const verticalY2 = lerp(
                y,
                lastFret + lastFretOffset,
                orientationProgress
              );
              
              return (
                <line
                  key={`h-${index}`}
                  x1={verticalX}
                  y1={verticalY}
                  x2={verticalX2}
                  y2={verticalY2}
                  stroke={theme.stringColor}
                  strokeWidth={getLineWidth(index, strings)}
                />
              );
            })}

            {/* Update vertical lines */}
            {lines.map((x, index) => {
              const stringSpacing = nodeRadius * 3;
              const firstString = 0;
              const lastString = isVertical
                ? stringSpacing * (Math.max(2, strings) - 1) // Only span across strings in vertical mode
                : dimensions.height;
              
              // Calculate the offset needed to center in vertical mode
              const verticalCenterOffset = isVertical
                ? (dimensions.height - lastString) / 2
                : 0;
              
              // Calculate opacity based on position
              let opacity = 1;
              if (index === 0) {
                opacity = nutScale;
              } else if (index === lines.length - 1) {
                opacity = rightScale;
              }

              // Calculate width for fret 0
              let fretWidth = regularFretWidth;
              if (!isInfiniteFrets && Math.floor(scrollPosition) === 0 && index === 0) {
                fretWidth = fret0Width;
              }
              
              // Add the offset to vertical X positions
              const verticalX = lerp(x, dimensions.height - firstString - verticalCenterOffset, orientationProgress);
              const verticalY = lerp(firstString, x, orientationProgress);
              const verticalX2 = lerp(x, dimensions.height - lastString - verticalCenterOffset, orientationProgress);
              const verticalY2 = lerp(lastString, x, orientationProgress);
              
              return (
                <line
                  key={`v-${index}`}
                  x1={verticalX}
                  y1={verticalY}
                  x2={verticalX2}
                  y2={verticalY2}
                  stroke={theme.fretColor}
                  strokeWidth={fretWidth}
                  opacity={opacity}
                />
              );
            })}

            {/* Update nodes */}
            {nodeVisibility.mounted && 
              intersections.map((point) => {
                const { stringIndex, fretIndex, currentFret } = point;
                const noteKey = `${stringIndex}-${currentFret}`;
                const animationState = nodeAnimationStates.get(noteKey);
                
                const rootNote = isInverted 
                  ? tuning[stringIndex] 
                  : tuning[tuning.length - 1 - stringIndex];
                
                const noteAtFret = !isInfiniteFrets ? getNoteAtFret(rootNote, currentFret, useFlats) : '';
                
                const isAccidental = Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]);
                const shouldHideNode = isAccidental && accidentalStyle === 'none';
                
                // Add scale filtering with explicit initial state handling
                const isInScale = selectedRoot && selectedScale && SCALES[selectedScale]
                  ? isNoteInScale(noteAtFret, selectedRoot, SCALES[selectedScale])
                  : selectedScale === 'Chromatic'; // Show all notes only if Chromatic is explicitly selected
                
                // Simplify the node scale calculation
                const nodeScale = animationState?.show || (isInScale && nodeVisibility.show) ? 1 : 0;

                if (!isInScale && !animationState) return null;

                const verticalX = lerp(
                  point.x,
                  dimensions.height - point.y - verticalCenterOffset,
                  orientationProgress
                );
                const verticalY = lerp(
                  point.y,
                  point.x,
                  orientationProgress
                );
                
                // Update the fill color calculation
                const nodeColor = (() => {
                  if (colorMode === 'Rainbow' && nodeDisplayMode === NODE_DISPLAY_MODES.INTERVALS && scaleRoot) {
                    // Get the interval index relative to the scale root
                    const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                    if (intervalIndex !== null) {
                      return NOTE_COLORS.rainbow[intervalIndex];
                    }
                  }
                  // Pass nodeDisplayMode and nodeTextMode to getNoteColor
                  return getNoteColor(noteAtFret, colorMode, theme, scaleRoot, nodeDisplayMode, nodeTextMode) || 
                         noteColors[noteAtFret] || 
                         theme.nodeColor;
                })();
                
                return (
                  <g 
                    key={`node-${stringIndex}-${currentFret}`}
                    onClick={() => onNodeClick?.(noteAtFret)}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* Root indicator - now first so it appears behind */}
                    {noteAtFret === scaleRoot && rootIndicator !== 'none' && (
                      <>
                        {rootIndicator === 'circle' && (
                          <circle
                            cx={verticalX}
                            cy={verticalY}
                            r={point.radius * 1.25}
                            fill={theme.diagramBg}
                            stroke={theme.nodeColor}
                            strokeWidth={2}
                          />
                        )}
                        {rootIndicator === 'square' && (
                          <rect
                            x={verticalX - point.radius * 1.25}
                            y={verticalY - point.radius * 1.25}
                            width={point.radius * 2.5}
                            height={point.radius * 2.5}
                            fill={theme.diagramBg}
                            stroke={theme.nodeColor}
                            strokeWidth={2}
                          />
                        )}
                      </>
                    )}

                    {/* Main node circle */}
                    <circle
                      cx={verticalX}
                      cy={verticalY}
                      r={point.radius + (colorMode === 'Piano' ? 0.5 : 0)}  // Add half the stroke width to radius
                      fill={colorMode === 'Piano' 
                        ? (Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]])
                          ? isDarkMode ? theme.diagramBg : theme.nodeColor  // Black keys
                          : isDarkMode ? theme.nodeColor : theme.diagramBg) // White keys
                        : nodeColor}
                      stroke={colorMode === 'Piano' 
                        ? (isDarkMode 
                            ? (Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]) ? '#777' : 'none')  // Dark mode: border only on black keys
                            : (Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]) ? 'none' : '#000')) // Light mode: border only on white keys
                        : 'none'}
                      strokeWidth={1}
                      opacity={point.opacity}
                      style={{
                        transform: `scale(${nodeScale})`,
                        transformOrigin: `${verticalX}px ${verticalY}px`,
                        transition: 'transform 200ms ease-out, opacity 200ms ease-out',
                      }}
                    />

                    {/* Note text */}
                    {showNodes && !isInfiniteFrets && (
                      nodeTextMode !== NODE_TEXT_MODES.HIDE_ALL && (
                        nodeTextMode === NODE_TEXT_MODES.SHOW_ALL || 
                        (nodeTextMode === NODE_TEXT_MODES.SHOW_ROOTS && noteAtFret === scaleRoot)
                      ) && (
                        <text
                          x={verticalX}
                          y={verticalY}
                          textAnchor="middle"
                          alignmentBaseline="central"
                          dominantBaseline="central"
                          fill={(() => {
                            // For root note in any mode, calculate based on red color
                            if ((colorMode === 'Default' || colorMode === 'Piano') && noteAtFret === scaleRoot) {
                              return getLuminance('#FF0000') ? '#FFFFFF' : '#000000';
                            }
                            
                            if (colorMode === 'Piano') {
                              return Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]) 
                                ? '#FFFFFF'  // White text for black keys
                                : '#000000'; // Black text for white keys
                            }
                            
                            // For Default mode, use contrasting color based on theme
                            if (colorMode === 'Default') {
                              return theme.diagramBg;
                            }
                            
                            // For Rainbow or custom colors, use luminance
                            return nodeColor && getLuminance(nodeColor) ? '#FFFFFF' : '#000000';
                          })()}
                          fontSize={point.radius * 1.25}
                          style={{
                            transform: `scale(${nodeScale})`,
                            transformOrigin: `${verticalX}px ${verticalY}px`,
                            transition: 'transform 200ms ease-out, opacity 200ms ease-out',
                            userSelect: 'none',
                            fontWeight: 'bold'
                          }}
                        >
                          {(() => {
                            // Show intervals when in interval mode and there's a scale root
                            if (nodeDisplayMode === NODE_DISPLAY_MODES.INTERVALS && scaleRoot) {
                              // Show the actual note for root notes, intervals for others
                              if (noteAtFret === scaleRoot) {
                                return formatNoteSymbol(noteAtFret, accidentalStyle);
                              }
                              const interval = getIntervalFromRoot(scaleRoot, noteAtFret);
                              return interval || '';
                            }
                            
                            // Otherwise show note names (existing behavior)
                            if (accidentalStyle === 'both' && Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]])) {
                              const formattedNote = formatNoteSymbol(noteAtFret, 'both');
                              return (
                                <tspan>
                                  <tspan x={verticalX} dy="-0.4em">{formattedNote.sharp}</tspan>
                                  <tspan x={verticalX} dy="0.8em">{formattedNote.flat}</tspan>
                                </tspan>
                              );
                            }
                            return formatNoteSymbol(noteAtFret, accidentalStyle);
                          })()}
                        </text>
                      )
                    )}
                  </g>
                );
              })}

            {/* Fret Numbers */}
            {!isInfiniteFrets &&
              lines.map((x, index) => {
                const fretNumber = visibleFrets[index];
                if (fretNumber === undefined || fretNumber < 0 || fretNumber > 24) return null;
                if (index === 0 && fretNumber !== 0) return null;

                const hasInlay = INLAY_POSITIONS.some(([_, end]) => end === fretNumber);
                
                let opacity;
                if (index === 0) {
                  opacity = nutScale;
                } else if (index === 1) {
                  opacity = nutScale;
                } else if (index === lines.length - 1) {
                  opacity = rightScale;
                } else {
                  opacity = 1;
                }

                let baseX = x;
                let offset;
                if (fretNumber === 0) {
                  baseX = showNodes 
                    ? x - (fret0Width / 2) - nodeRadius
                    : x;
                } else {
                  const scale = index === 1 ? nutScale : 1;
                  offset = showNodes 
                    ? -(nodeRadius + stringSizeBase * 1) * scale 
                    : -(stringSizeBase * 1) * scale;
                }

                // Get the last string position (thickest string) for vertical mode
                const lastString = stringLinePositions[stringLinePositions.length - 1];
                // Center in the bottom padding area for horizontal mode
                const bottomPaddingCenterY = dimensions.height + (PADDING.bottom / 2) + 
                  (!isVertical && showNodes ? nodeRadius * 0.5 - (bottomStringThickness / 2) : 0);  // Add offset when horizontal with nodes
                const labelOffset = nodeRadius * 3;

                const verticalX = lerp(
                  baseX,
                  dimensions.height - lastString - labelOffset - verticalCenterOffset,
                  orientationProgress
                );
                const verticalY = lerp(
                  bottomPaddingCenterY,
                  baseX,
                  orientationProgress
                );

                // Don't apply position transition for fret 0
                const transform = fretNumber === 0
                  ? ''
                  : `${isVertical ? 'rotate(0)' : ''} translate(${offset * (1 - orientationProgress)}, ${offset * orientationProgress})`;

                return (
                  <g key={`fret-${fretNumber}`}>
                    {showInlays && hasInlay && (
                      <circle
                        ref={node => {
                          if (node) {
                            try {
                              // Get the text element's bounding box
                              const textElement = node.parentNode.querySelector('text');
                              if (textElement) {
                                const bbox = textElement.getBBox();
                                // Make circle smaller by using 0.6 instead of 0.7
                                const circleRadius = Math.max(bbox.width, bbox.height) * 0.6;
                                node.setAttribute('r', circleRadius);
                                node.setAttribute('cx', bbox.x + bbox.width / 2);
                                node.setAttribute('cy', bbox.y + bbox.height / 2);
                              }
                            } catch (error) {
                              console.warn('Error setting circle position:', error);
                            }
                          }
                        }}
                        fill={theme.inlayColor}
                        stroke="none"
                        opacity={opacity * 0.25}
                        transform={transform}
                      />
                    )}
                    <text
                      x={verticalX}
                      y={verticalY}
                      textAnchor="middle"
                      alignmentBaseline="central"
                      fill={theme.stringColor}
                      fontSize={nodeRadius * 1}
                      opacity={opacity}
                      transform={transform}
                      onClick={() => onNodeClick?.(null)} // Unset root when clicking fret numbers
                    >
                      {fretNumber}
                    </text>
                  </g>
                );
              })}
          </g>
        </svg>
      </div>
    </div>
  );
});

export default Diagram;
