import React, { useState, useEffect, useRef } from 'react';
import { darkTheme, lightTheme } from './Themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faShapes,
  faMusic,
  faRotateRight,
  faRotateLeft,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faSquareDashed } from '@fortawesome/pro-solid-svg-icons';
import FretboardRenderer from './Diagram';
import { exportAsPNG } from './Export';
import { INSTRUMENT_PRESETS, NOTES, SCALES, NOTE_TO_INDEX, NOTE_COLORS } from './Controls';
import Theory from './Controls';
import Visuals from './Controls';
import { NODE_TEXT_MODES, NODE_DISPLAY_MODES } from './Diagram';

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isInverted, setisInverted] = useState(false);
  const [panelSize, setPanelSize] = useState((window.innerHeight - 70) / 2);
  const [strings, setStrings] = useState(6);
  const [showNodes, setShowNodes] = useState(true);
  const [stringSizeBase, setStringSizeBase] = useState(1);
  const [contentHeight, setContentHeight] = useState((window.innerHeight - 70) / 2);
  const [isDragging, setIsDragging] = useState(false);
  const [isInfiniteFrets, setIsInfiniteFrets] = useState(false);
  const [showInlays, setShowInlays] = useState(true);
  const [isVertical, setIsVertical] = useState(false);
  const [fretDensity, setFretDensity] = useState(3.5);
  const [viewBoxHeight, setViewBoxHeight] = useState(0);
  const [currentTuning, setCurrentTuning] = useState(['E', 'A', 'D', 'G', 'B', 'E']);
  const [useFlats, setUseFlats] = useState(false);
  const [accidentalStyle, setAccidentalStyle] = useState('sharp');
  const [showBounds, setShowBounds] = useState(false);

  const controlPanelRef = useRef(null);
  const controlPanelContentRef = useRef(null);
  const svgRef = useRef(null);

  const theme = isDarkMode ? darkTheme : lightTheme;

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleInvertFrets = () => {
    setisInverted(!isInverted);
  };

  const toggleFixed = () => {
    setIsInfiniteFrets(!isInfiniteFrets);
  };

  const toggleInlays = () => {
    setShowInlays(!showInlays);
  };

  const toggleOrientation = () => {
    setIsVertical(!isVertical);
  };

  const handleFretsChange = (e) => {
    setFretDensity(parseFloat(e.target.value));
  };

  const [currentInstrument, setCurrentInstrument] = useState("Guitar");
  const [currentPreset, setCurrentPreset] = useState(INSTRUMENT_PRESETS[0]);

  const handleInstrumentChange = (e) => {
    const [instrument, stringCount] = e.target.value.split('|');
    const strings = parseInt(stringCount, 10);
    
    const selectedInstrument = INSTRUMENT_PRESETS.find(
      preset => preset.instrument === instrument && preset.strings === strings
    );

    if (selectedInstrument) {
      setCurrentInstrument(instrument);
      setStrings(selectedInstrument.strings);
      setCurrentTuning(selectedInstrument.tuning);
      setCurrentPreset(selectedInstrument);
    }
  };

  const handleStringSizeBaseChange = (e) => {
    setStringSizeBase(parseFloat(e.target.value));
  };

  const toggleShowNodes = () => {
    if (selectedScale !== 'None') {
      setShowNodes(!showNodes);
    }
  };

  const handleExport = async () => {
    const svgElement = svgRef.current;
    if (!svgElement) return;
    
    try {
      await exportAsPNG(svgElement);
    } catch (err) {
      console.error('Export failed:', err);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // No longer needed since we're always in stacked mode
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [dragStartY, setDragStartY] = useState(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging || dragStartY === null) return;
      
      const totalAvailableHeight = window.innerHeight - 70;
      const minControlPanelHeight = (controlPanelContentRef.current?.scrollHeight || 100) + 40;
      
      // Calculate fixed dimensions
      const UNIT = window.innerWidth >= 500 ? 20 : window.innerWidth * 0.04;
      const nodeRadius = UNIT * 0.75;
      const paddingScale = Math.max(1, 1 + (strings * 0.2));
      const TOP_PADDING = nodeRadius * paddingScale;
      const BOTTOM_PADDING = nodeRadius * 2 * paddingScale;
      const stringSpacing = nodeRadius * 3;

      if (isVertical) {
        const deltaY = e.clientY - dragStartY;
        const newPanelSize = panelSize - deltaY;
        const availableHeight = totalAvailableHeight - newPanelSize;
        
        setPanelSize(Math.max(minControlPanelHeight, Math.min(newPanelSize, totalAvailableHeight - minControlPanelHeight)));
        setContentHeight(availableHeight);
        setViewBoxHeight(availableHeight);
        setDragStartY(e.clientY);
      } else {
        // Always use at least 2 strings for spacing calculation
        const totalSpacing = stringSpacing * (Math.max(2, strings) - 1);
        const minHeight = totalSpacing + (nodeRadius * 4);
        setPanelSize(Math.max(minControlPanelHeight, totalAvailableHeight - minHeight));
        setContentHeight(minHeight);
      }
    };

    const handleTouchMove = (e) => {
      if (!isDragging || dragStartY === null) return;
      e.preventDefault();
      const touch = e.touches[0];
      
      const totalAvailableHeight = window.innerHeight - 70;
      const minControlPanelHeight = (controlPanelContentRef.current?.scrollHeight || 100) + 40;
      
      // Calculate fixed dimensions
      const UNIT = window.innerWidth >= 500 ? 20 : window.innerWidth * 0.04;
      const nodeRadius = UNIT * 0.75;
      const paddingScale = Math.max(1, 1 + (strings * 0.2));
      const TOP_PADDING = nodeRadius * paddingScale;
      const BOTTOM_PADDING = nodeRadius * 2 * paddingScale;
      const stringSpacing = nodeRadius * 3;

      if (isVertical) {
        const deltaY = touch.clientY - dragStartY;
        const newPanelSize = panelSize - deltaY;
        const availableHeight = totalAvailableHeight - newPanelSize;
        
        setPanelSize(Math.max(minControlPanelHeight, Math.min(newPanelSize, totalAvailableHeight - minControlPanelHeight)));
        setContentHeight(availableHeight);
        setViewBoxHeight(availableHeight);
        setDragStartY(touch.clientY);
      } else {
        // Always use at least 2 strings for spacing calculation
        const totalSpacing = stringSpacing * (Math.max(2, strings) - 1);
        const minHeight = totalSpacing + (nodeRadius * 4);
        setPanelSize(Math.max(minControlPanelHeight, totalAvailableHeight - minHeight));
        setContentHeight(minHeight);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setDragStartY(null);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging, isVertical, strings, panelSize, dragStartY]);

  const UNIT = window.innerWidth >= 500 ? 20 : window.innerWidth * 0.04;

  // Define PADDING similar to FretboardRenderer.js
  const PADDING = {
    title: UNIT * 3.5
  };

  useEffect(() => {
    const calculateHeights = () => {
      const totalAvailableHeight = window.innerHeight;
      const minControlPanelHeight = (controlPanelContentRef.current?.scrollHeight || 100) + 40;
      
      const nodeRadius = UNIT * 0.75;
      const stringSpacing = nodeRadius * 3;
      
      if (isVertical) {
        const paddingScale = Math.max(1, 1 + (strings * 0.2));
        const TOP_PADDING = nodeRadius * paddingScale;
        const BOTTOM_PADDING = nodeRadius * 2 * paddingScale;
        const fixedGridHeight = stringSpacing * Math.max(2, strings) + TOP_PADDING + BOTTOM_PADDING + 32;
        const newPanelSize = totalAvailableHeight - fixedGridHeight - 80;
        setPanelSize(Math.max(minControlPanelHeight, newPanelSize));
        setContentHeight(fixedGridHeight);
      } else {
        const totalSpacing = stringSpacing * (Math.max(2, strings) - 1);
        const minHeight = totalSpacing + (nodeRadius * 4);
        setPanelSize(Math.max(minControlPanelHeight, totalAvailableHeight - minHeight - 80));
        setContentHeight(minHeight);
      }
    };

    calculateHeights();
  }, [strings, showNodes, isVertical, UNIT]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setDragStartY(e.clientY);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setDragStartY(e.touches[0].clientY);
  };

  // Move all styles into component
  const headerStyle = (theme) => ({
    minHeight: '60px',
    minWidth: '100%',
    backgroundColor: theme.headerBg,
    color: theme.headerColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  });

  const appContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100dvh',
    width: '100dvw',
    justifyContent: 'left',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100dvh',
    width: '100dvw',
    overflow: 'hidden',
  };

  const controlPanelStyle = (theme, panelSize) => ({
    backgroundColor: theme.controlPanelBg,
    width: '100%',
    flex: 1,
    display: 'flex',
    position: 'relative',
    color: theme.textColor,
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '20px',
    gap: '20px',
    boxSizing: 'border-box',
    overflow: 'auto'
  });

  const controlPanelGroup = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    padding: '10px',
    backgroundColor: theme.controlPanelGroupBg,
    borderRadius: '10px',
  });

  const resizerStyle = (theme, isDragging) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    minHeight: '5px',
    cursor: 'default',
    color: theme.buttonColor,
    backgroundColor: isDragging ? theme.buttonBg : theme.resizeBg,
    transition: 'background-color 0.2s',
    userSelect: 'none',
    touchAction: 'none'
  });

  const buttonStyle = (theme) => ({
    backgroundColor: theme.buttonBg,
    color: theme.buttonColor,
    border: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '5px',
  });

  const sliderStyle = {
    width: '80%',
    margin: '10px 0',
  };

  const layoutButtonStyle = {
    position: 'absolute',
    right: 0,
    paddingRight: '10px',
    top: 0,
    paddingTop: '10px',
  };

  const controlPanelContentStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'flex-start',
  };

  // Add callback to handle fret density updates from FretboardRenderer
  const handleFretDensityChange = (newDensity) => {
    setFretDensity(newDensity);
  };

  // Add separate hover states for each button
  const [hoverStates, setHoverStates] = useState({
    infinite: false,
    rotate: false,
    accidental: false
  });

  // Add new ref for title area
  const titleAreaRef = useRef(null);
  const [titlePosition, setTitlePosition] = useState(null);

  // Update effect to use screen coordinates
  useEffect(() => {
    const updateTitlePosition = () => {
      if (titleAreaRef.current) {
        setTitlePosition(titleAreaRef.current);
      }
    };

    const observer = new ResizeObserver(updateTitlePosition);
    
    // Observe the container div instead of the SVG element
    const container = document.querySelector('.w-full.h-full.flex');
    if (container) {
      observer.observe(container);
      updateTitlePosition();
    }

    return () => observer.disconnect();
  }, []);

  // Update the overlayButtonStyle to use screen coordinates
  const overlayButtonStyle = (theme, position = 'right', isHovering) => ({
    position: 'absolute',
    top: titlePosition?.center || PADDING.title / 2,
    ...(position === 'right' 
      ? { right: UNIT * 0.5 } 
      : { left: UNIT * 0.5 }
    ),
    zIndex: 10,
    backgroundColor: isHovering ? theme.buttonBg : 'transparent',
    color: theme.diagramButton,
    border: 'none',
    cursor: 'pointer',
    width: UNIT * 2,
    height: UNIT * 2,
    borderRadius: UNIT * 0.25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isHovering ? 1 : 0.8,
    transition: 'all 0.2s',
    transform: 'translateY(-50%)'
  });

  const cycleAccidentalStyle = () => {
    setAccidentalStyle(current => {
      switch (current) {
        case 'sharp':
          return 'flat';
        case 'flat':
          return 'sharp';
        default:
          return 'sharp';
      }
    });
  };

  const toggleShowBounds = () => {
    setShowBounds(!showBounds);
  };

  // Add styles for the navigation bar
  const navigationStyle = (theme) => ({
    minHeight: '60px',
    minWidth: '100%',
    backgroundColor: theme.headerBg,
    color: theme.headerColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  });

  // Add new state for active panel
  const [activePanel, setActivePanel] = useState('setup'); // 'setup', 'music', 'shapes', 'draw', 'play'

  // Add these style updates
  const navigationButtonStyle = (theme, isActive) => ({
    color: isActive ? theme.toolbarSelected : theme.toolbarUnselected,
    cursor: 'pointer',
    padding: '10px',
    transition: 'color 0.2s',
  });

  // Update the handlePanelChange to only handle visual state
  const handlePanelChange = (newPanel) => {
    setActivePanel(newPanel);
  };

  // Update the navigation bar section to use the new handler
  const navigationBar = (
    <div style={navigationStyle(theme)}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FontAwesomeIcon 
          icon={faCircle} 
          style={navigationButtonStyle(theme, activePanel === 'setup')}
          onClick={() => handlePanelChange('setup')}
        />
        <span style={{ fontSize: '0.7em', marginTop: '2px', color: activePanel === 'setup' ? theme.toolbarSelected : theme.toolbarUnselected }}>Root</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FontAwesomeIcon 
          icon={faMusic}
          style={navigationButtonStyle(theme, activePanel === 'edit')}
          onClick={() => handlePanelChange('edit')}
        />
        <span style={{ fontSize: '0.7em', marginTop: '2px', color: activePanel === 'edit' ? theme.toolbarSelected : theme.toolbarUnselected }}>Edit</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FontAwesomeIcon 
          icon={faSquareDashed}
          style={navigationButtonStyle(theme, activePanel === 'focus')}
          onClick={() => handlePanelChange('focus')}
        />
        <span style={{ fontSize: '0.7em', marginTop: '2px', color: activePanel === 'focus' ? theme.toolbarSelected : theme.toolbarUnselected }}>Focus</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FontAwesomeIcon 
          icon={faShapes} 
          style={navigationButtonStyle(theme, activePanel === 'visuals')}
          onClick={() => handlePanelChange('visuals')}
        />
        <span style={{ fontSize: '0.7em', marginTop: '2px', color: activePanel === 'visuals' ? theme.toolbarSelected : theme.toolbarUnselected }}>Draw</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FontAwesomeIcon 
          icon={faPlay} 
          style={navigationButtonStyle(theme, activePanel === 'play')}
          onClick={() => handlePanelChange('play')}
        />
        <span style={{ fontSize: '0.7em', marginTop: '2px', color: activePanel === 'play' ? theme.toolbarSelected : theme.toolbarUnselected }}>Play</span>
      </div>
    </div>
  );

  // Add these state variables
  const [selectedKey, setSelectedKey] = useState('G');
  const [selectedScale, setSelectedScale] = useState('Major');
  const [previewNote, setPreviewNote] = useState(null);
  const [noteColors, setNoteColors] = useState({});
  const [scaleRoot, setScaleRoot] = useState(null);
  const [rootIndicator, setRootIndicator] = useState('circle');

  const [colorMode, setColorMode] = useState('Default');

  // Near the top of the App component, with other useState declarations
  const [noteColor, setNoteColor] = useState('#ffffff'); // or whatever default color you want

  // Add this with other state declarations at the top of the App component
  const [nodeDisplayMode, setNodeDisplayMode] = useState(NODE_DISPLAY_MODES.INTERVALS);

  // Update the renderControlPanelContent to always return Theory component
  const renderControlPanelContent = () => {
    return (
      <Theory
        selectedRoot={selectedKey}
        selectedScale={selectedScale}
        onRootChange={setSelectedKey}
        onScaleChange={setSelectedScale}
        theme={theme}
        onThemeChange={toggleTheme}
        isDarkMode={isDarkMode}
        nodeTextMode={nodeTextMode}
        onNodeTextModeChange={setNodeTextMode}
        onExport={handleExport}
        showBounds={showBounds}
        onShowBoundsChange={toggleShowBounds}
        colorMode={colorMode}
        onColorModeChange={setColorMode}
        noteColors={noteColors}
        onNoteColorChange={(note, color) => {
          setNoteColors(prev => ({
            ...prev,
            [note]: color === 'default' ? null : color
          }));
        }}
        previewNote={previewNote}
        currentPreset={currentPreset}
        onInstrumentChange={handleInstrumentChange}
        onScaleRootChange={setScaleRoot}
        setShowNodes={setShowNodes}
        onInfiniteModeChange={setIsInfiniteFrets}
        onInvertChange={toggleInvertFrets}
        isInverted={isInverted}
        onFixedChange={toggleFixed}
        isInfiniteFrets={isInfiniteFrets}
        onAccidentalStyleChange={(style) => setAccidentalStyle(style)}
      />
    );
  };

  // Add this helper function near the top of the App component
  const currentScaleHasAccidentals = () => {
    if (selectedScale === 'Chromatic') return true;
    
    if (!selectedScale || !selectedKey || !SCALES[selectedScale]) return false;
    
    const rootIndex = NOTE_TO_INDEX[selectedKey];
    const scaleNotes = SCALES[selectedScale].map(interval => {
      const noteIndex = (rootIndex + interval) % 12;
      return NOTES[noteIndex];
    });
    
    return scaleNotes.some(note => Array.isArray(note));
  };

  // Update the handleNodeClick to only handle root selection
  const handleNodeClick = (note) => {
    // Always set scale root when clicking a note, regardless of active panel
    setScaleRoot(note);
  };

  const [showNodeText, setShowNodeText] = useState(true);

  const [nodeTextMode, setNodeTextMode] = useState(NODE_TEXT_MODES.SHOW_ALL);

  return (
    <div style={appContainerStyle}>
      <div style={contentStyle}>
        <div style={{ position: 'relative' }}>
          {currentScaleHasAccidentals() && (
            <button 
              style={overlayButtonStyle(theme, 'left', hoverStates.accidental)}
              onClick={cycleAccidentalStyle}
              onMouseEnter={() => setHoverStates(prev => ({ ...prev, accidental: true }))}
              onMouseLeave={() => setHoverStates(prev => ({ ...prev, accidental: false }))}
            >
              <span style={{ fontSize: UNIT }}>
                {accidentalStyle === 'sharp' ? '♯' : '♭'}
              </span>
            </button>
          )}
          <button 
            style={overlayButtonStyle(theme, 'right', hoverStates.rotate)} 
            onClick={toggleOrientation}
            onMouseEnter={() => setHoverStates(prev => ({ ...prev, rotate: true }))}
            onMouseLeave={() => setHoverStates(prev => ({ ...prev, rotate: false }))}
          >
            <FontAwesomeIcon 
              icon={isVertical ? faRotateLeft : faRotateRight} 
              style={{ fontSize: UNIT }}
            />
          </button>
          <FretboardRenderer 
            ref={svgRef}
            titleAreaRef={titleAreaRef}
            unit={UNIT}
            strings={strings} 
            showNodes={showNodes} 
            height={contentHeight}
            isDarkMode={isDarkMode}
            stringSizeBase={stringSizeBase}
            isInfiniteFrets={isInfiniteFrets}
            showInlays={showInlays}
            isVertical={isVertical}
            fretDensity={fretDensity}
            onFretDensityChange={handleFretDensityChange}
            isInverted={isInverted}
            tuning={currentTuning}
            useFlats={accidentalStyle === 'flat'}
            accidentalStyle={accidentalStyle}
            onTuningChange={setCurrentTuning}
            showBounds={showBounds}
            selectedRoot={selectedKey}
            selectedScale={selectedScale}
            onNodeClick={handleNodeClick}
            noteColors={noteColors}
            scaleRoot={scaleRoot}
            rootIndicator={rootIndicator}
            colorMode={colorMode}
            currentPreset={currentPreset}
            showNodeText={showNodeText}
            nodeTextMode={nodeTextMode}
            nodeDisplayMode={nodeDisplayMode}
          />
        </div>
        <div 
          style={resizerStyle(theme, isDragging)}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        />
        {navigationBar}
        <div
          ref={controlPanelRef}
          style={controlPanelStyle(theme, panelSize)}
        >
          <div ref={controlPanelContentRef} style={controlPanelContentStyle}>
            {renderControlPanelContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
