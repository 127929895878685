const exportAsPNG = async (svgElement, filename = 'fretboard.png') => {
  // Get the SVG dimensions and viewBox
  const svgRect = svgElement.getBoundingClientRect();
  const viewBox = svgElement.getAttribute('viewBox').split(' ').map(Number);
  
  // Create a copy of the SVG with higher resolution (8x)
  const svgClone = svgElement.cloneNode(true);
  const scale = 8;
  const width = svgRect.width * scale;
  const height = svgRect.height * scale;
  
  // Set the dimensions on the clone
  svgClone.setAttribute('width', width);
  svgClone.setAttribute('height', height);
  
  // Add xmlns attribute to ensure proper SVG namespace
  svgClone.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  
  // Copy computed styles from the original SVG elements to the clone
  const elements = svgClone.querySelectorAll('*');
  elements.forEach((el, index) => {
    const originalEl = svgElement.querySelectorAll('*')[index];
    if (!originalEl) return;

    const computedStyle = window.getComputedStyle(originalEl);
    
    // Copy font styles
    el.style.fontFamily = computedStyle.fontFamily;
    el.style.fontSize = computedStyle.fontSize;
    el.style.fontWeight = computedStyle.fontWeight;
    el.style.fontStyle = computedStyle.fontStyle;
    
    // Remove any background color
    if (el.getAttribute('fill') === '#111') {
      el.setAttribute('fill', 'none');
    }
    
    // Convert dark theme colors to light theme
    if (el.getAttribute('fill') === '#ccc' || el.getAttribute('fill') === '#aaa') {
      el.setAttribute('fill', '#000');
    }
    if (el.getAttribute('stroke') === '#ccc' || el.getAttribute('stroke') === '#aaa') {
      el.setAttribute('stroke', '#000');
    }
  });
  
  // Convert SVG to base64
  const svgData = new XMLSerializer().serializeToString(svgClone);
  const svgBase64 = btoa(unescape(encodeURIComponent(svgData)));
  const base64Url = `data:image/svg+xml;base64,${svgBase64}`;
  
  // Create Image and Canvas
  const img = new Image();
  img.crossOrigin = 'anonymous'; // Add cross-origin attribute
  
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      
      const ctx = canvas.getContext('2d');
      // Make canvas transparent instead of white
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);
      
      // Convert to PNG and download
      try {
        const link = document.createElement('a');
        link.download = filename;
        link.href = canvas.toDataURL('image/png');
        link.click();
        resolve();
      } catch (err) {
        reject(err);
      }
    };
    
    img.onerror = reject;
    // Use base64 data URL instead of Blob URL
    img.src = base64Url;
  });
};

export { exportAsPNG }; 