import { formatNoteSymbol } from './Diagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLineHeight, faMoon, faFont, faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { NODE_TEXT_MODES } from './Diagram';

export const NOTES = [
  'A',
  ['A#', 'Bb'],
  'B',
  'C',
  ['C#', 'Db'],
  'D',
  ['D#', 'Eb'],
  'E',
  'F',
  ['F#', 'Gb'],
  'G',
  ['G#', 'Ab']
];

export const NOTE_TO_INDEX = NOTES.reduce((acc, note, index) => {
  if (Array.isArray(note)) {
    // Handle sharps and flats
    note.forEach(noteName => {
      acc[noteName] = index;
    });
  } else {
    // Handle single notes
    acc[note] = index;
  }
  return acc;
}, {});

export const INSTRUMENT_PRESETS = [
  { instrument: "Guitar", strings: 6, tuning: ['E', 'A', 'D', 'G', 'B', 'E'] },
  { instrument: "Guitar", strings: 7, tuning: ['B', 'E', 'A', 'D', 'G', 'B', 'E'] },
  { instrument: "Bass", strings: 4, tuning: ['E', 'A', 'D', 'G'] },
  { instrument: "Bass", strings: 5, tuning: ['B', 'E', 'A', 'D', 'G'] },
  { instrument: "Bass", strings: 6, tuning: ['B', 'E', 'A', 'D', 'G', 'C'] },
  { instrument: "Bass", strings: 7, tuning: ['B', 'E', 'A', 'D', 'G', 'C', 'F'] },
  { instrument: "Ukulele", strings: 4, tuning: ['G', 'C', 'E', 'A'] },
  { instrument: "Mandolin", strings: 4, tuning: ['G', 'D', 'A', 'E'] },
  { instrument: "Banjo", strings: 4, tuning: ['D', 'G', 'B', 'D'] },
  { instrument: "Violin", strings: 4, tuning: ['G', 'D', 'A', 'E'] },
  { instrument: "Custom", strings: 3, tuning: ['G', 'D', 'A'] },
  { instrument: "Custom", strings: 2, tuning: ['D', 'A'] },
  { instrument: "Custom", strings: 1, tuning: ['E'] },
];

// Add a helper function to generate the display label
const getPresetLabel = (instrument, strings) => `${instrument} - ${strings} String`;

// Update these scale definitions
export const SCALES = {
  'None': null,
  Chromatic: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  'Diminished WH': [0, 2, 3, 5, 6, 8, 9, 11],  // Whole-Half pattern
  'Diminished HW': [0, 1, 3, 4, 6, 7, 9, 10],  // Half-Whole pattern
  'Wholetone': [0, 2, 4, 6, 8, 10],
  Pentatonic: [0, 2, 4, 7, 9],
  Blues: [0, 3, 5, 6, 7, 10],
  'Major': [0, 2, 4, 5, 7, 9, 11],
  'Melodic Minor': [0, 2, 3, 5, 7, 9, 11],
  'Harmonic Minor': [0, 2, 3, 5, 7, 8, 11],
  'Harmonic Major': [0, 2, 4, 5, 7, 8, 11],
};

// Add this constant for Nashville numbers
export const NASHVILLE_NUMBERS = {
  'Major': [
    'I',    // Major
    'ii',   // minor
    'iii',  // minor
    'IV',   // Major
    'V',    // Major
    'vi',   // minor
    'vii°'  // diminished
  ]
};

// Update helper function to check if a note is in scale
export const isNoteInScale = (note, rootNote, scale) => {
  // Return false when no scale is selected or scale is None
  if (!scale) return false;
  
  // Show all notes for Chromatic scale
  if (scale === SCALES.Chromatic) return true;
  
  if (!note || !rootNote) return false;
  
  const rootIndex = NOTE_TO_INDEX[rootNote];
  const noteIndex = NOTE_TO_INDEX[note];
  
  if (rootIndex === undefined || noteIndex === undefined) return false;
  
  // Calculate the interval between root and note
  const interval = (noteIndex - rootIndex + 12) % 12;
  return scale.includes(interval);
};

// Add mode names in order
export const MAJOR_MODES = [
  'Ionian (Major)',      // 1st degree
  'Dorian',      // 2nd degree
  'Phrygian',    // 3rd degree
  'Lydian',      // 4th degree
  'Mixolydian',  // 5th degree
  'Aeolian (Natural Minor)',     // 6th degree
  'Locrian'      // 7th degree
];

// Add melodic minor modes after MAJOR_MODES
export const MELODIC_MINOR_MODES = [
  'Melodic Minor',    // 1st degree
  'Dorian b2',        // 2nd degree
  'Lydian Augmented', // 3rd degree
  'Lydian Dominant',  // 4th degree
  'Mixolydian b6',    // 5th degree
  'Aeolian b5',       // 6th degree
  'Altered Scale'     // 7th degree
];

// Add harmonic minor modes after MELODIC_MINOR_MODES
export const HARMONIC_MINOR_MODES = [
  'Harmonic Minor',      // 1st degree
  'Locrian ♮6',         // 2nd degree
  'Ionian Augmented',    // 3rd degree
  'Dorian #4',          // 4th degree
  'Phrygian Dominant',   // 5th degree
  'Lydian #2',          // 6th degree
  'Super Locrian bb7'    // 7th degree
];

// Add harmonic major modes after HARMONIC_MINOR_MODES
export const HARMONIC_MAJOR_MODES = [
  'Harmonic Major',      // 1st degree
  'Dorian b5',          // 2nd degree
  'Phrygian b4',        // 3rd degree
  'Lydian b3',          // 4th degree
  'Mixolydian b2',      // 5th degree
  'Lydian Augmented #2', // 6th degree
  'Locrian bb7'         // 7th degree
];

// Add this constant at the top with other constants
const FLAT_KEYS = ['F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb'];

// Add this constant with the other mode constants
export const PENTATONIC_MODES = [
  'Major Pentatonic',        // 1st degree
  '',    // 2nd degree
  '',             // 3rd degree
  '',             // 4th degree
  'Minor Pentatonic'         // 5th degree
];

// Add helper function to get key options
const getKeyOptions = (scale) => {
  if (scale === 'Chromatic') return ['C'];
  return NOTES.map(note => Array.isArray(note) ? note[0] : note);
};

// Update helper function for enharmonic spelling
const getEnharmonicSpelling = (note, key, scale) => {
  if (!Array.isArray(NOTES[NOTE_TO_INDEX[note]])) return note;
  
  // Get both possible spellings
  const [sharpSpelling, flatSpelling] = NOTES[NOTE_TO_INDEX[note]];
  
  // Use flats for flat keys
  if (FLAT_KEYS.includes(key)) {
    return flatSpelling;
  }
  
  // Use sharps for other keys
  return sharpSpelling;
};

// Add this constant for interval names
export const INTERVAL_NAMES = {
  0: 'R',
  1: '♭2',
  2: '2',
  3: '♭3',
  4: '3',
  5: '4',
  6: '♭5',
  7: '5',
  8: '♭6',
  9: '6',
  10: '♭7',
  11: '7'
};

export const NOTE_COLORS = {
  rainbow: [
    '#FF0000', // Red (Root)
    '#FF009F', // Rose
    '#CC00FF', // Magenta
    '#0040FF', // Azure-Blue
    '#007FFF', // Azure
    '#00FF00', // Green
    '#9FFF00', // Yellow-Green
    '#FFFF00', // Yellow
    '#FFBF00', // Orange-Yellow
    '#FF9F00', // Orange-Yellow
    '#FF7F00', // Orange
    '#FF5F00', // Red-Orange
  ]
};

const getLuminance = (hexColor) => {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16) / 255;
  const g = parseInt(hex.substr(2, 2), 16) / 255;
  const b = parseInt(hex.substr(4, 2), 16) / 255;
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 0.5;
};

export const PreviewNode = ({ note, theme, color, colorMode, size = 40 }) => {
  const nodeSize = size;
  
  const getNodeColor = () => {
    if (colorMode === 'Default') {
      return color || theme.nodeColor;
    }
    if (colorMode === 'Rainbow') {
      const noteIndex = NOTE_TO_INDEX[note];
      if (noteIndex === undefined) return theme.nodeColor;
      const shiftedIndex = (noteIndex - 3 + 12) % 12;
      return NOTE_COLORS.rainbow[shiftedIndex];
    }
    if (colorMode === 'Piano') {
      const isAccidental = Array.isArray(NOTES[NOTE_TO_INDEX[note]]);
      return isAccidental ? theme.diagramBg : theme.nodeColor;
    }
    return theme.nodeColor;
  };

  const nodeColor = getNodeColor();
  const isAccidental = Array.isArray(NOTES[NOTE_TO_INDEX[note]]);

  const getTextColor = () => {
    if (colorMode === 'Piano') {
      return isAccidental ? '#FFFFFF' : '#000000';
    }
    return nodeColor && getLuminance(nodeColor) ? '#FFFFFF' : '#000';
  };

  const getNoteText = () => {
    const formattedNote = formatNoteSymbol(note, 'both');
    if (typeof formattedNote === 'object') {
      return (
        <tspan>
          <tspan x={nodeSize} dy="-0.15em" fontSize={nodeSize * 0.6}>{formattedNote.sharp}</tspan>
          <tspan x={nodeSize} dy="1em" fontSize={nodeSize * 0.6}>{formattedNote.flat}</tspan>
        </tspan>
      );
    }
    return formattedNote;
  };
  
  return (
    <svg width={nodeSize * 2} height={nodeSize * 2}>
      <circle
        cx={nodeSize}
        cy={nodeSize}
        r={nodeSize * 0.75}
        fill={nodeColor}
        stroke={colorMode === 'Piano' && isAccidental ? theme.nodeColor : 'none'}
        strokeWidth={colorMode === 'Piano' ? 1 : 0}
      />
      <text
        x={nodeSize}
        y={nodeSize}
        textAnchor="middle"
        alignmentBaseline="central"
        fill={getTextColor()}
        fontSize={isAccidental ? nodeSize * 0.6 : nodeSize * 0.8}
        style={{
          userSelect: 'none',
          fontWeight: 'bold'
        }}
      >
        {getNoteText()}
      </text>
    </svg>
  );
};

const Theory = ({ 
  selectedRoot = null,
  selectedScale = 'None',
  onRootChange, 
  onScaleChange,
  theme,
  previewNote,
  onNoteColorChange,
  selectedColor,
  scaleRoot,
  onScaleRootChange,
  rootIndicator = 'circle',
  onRootIndicatorChange,
  onAccidentalStyleChange,
  setShowNodes,
  onInfiniteModeChange,
  currentInstrument,
  currentPreset,
  onInstrumentChange,
  isInverted,
  onInvertChange,
  isInfiniteFrets,
  onFixedChange,
  noteColors,
  onColorModeChange,
  colorMode,
  onThemeChange,
  isDarkMode,
  nodeTextMode,
  onNodeTextModeChange,
  onExport,
  showBounds,
  onShowBoundsChange
}) => {
  const controlPanelGroup = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    padding: '10px',
    backgroundColor: theme.controlPanelGroupBg,
    borderRadius: '10px',
  };

  const buttonStyle = {
    backgroundColor: theme.buttonBg,
    color: theme.buttonColor,
    border: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '5px',
  };

  const selectStyle = {
    backgroundColor: theme.buttonBg,
    color: theme.buttonColor,
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    flex: 1,
  };

  const instrumentControlsStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
  };

  // Update helper function to get formatted key list in chromatic order starting with Ab
  const getFormattedKeys = () => {
    const keys = ['Ab', 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G'];
    return keys.map(key => ({
      value: key,
      display: formatNoteSymbol(key)
    }));
  };

  // Update handler for key changes
  const handleKeyChange = (value) => {
    onRootChange(value || null);
    onScaleRootChange(null);  // Always set scale root to null when changing key
    
    if (value) {
      // Use flats for flat keys, sharps for others
      if (FLAT_KEYS.includes(value)) {
        onAccidentalStyleChange('flat');
      } else {
        onAccidentalStyleChange('sharp');
      }
    }

    // Turn off infinite mode when a key is selected
    if (selectedScale === 'Major') {
      onInfiniteModeChange?.(value === '');
    }
  };

  // Update getNoteWithMode function to include pentatonic modes
  const getNoteWithMode = (note, interval) => {
    switch(selectedScale) {
      case 'Major':
        const majorIndex = SCALES[selectedScale].indexOf(interval);
        return `${formatNoteSymbol(note)} - ${MAJOR_MODES[majorIndex]}`;
      case 'Melodic Minor':
        const melodicIndex = SCALES[selectedScale].indexOf(interval);
        return `${formatNoteSymbol(note)} - ${MELODIC_MINOR_MODES[melodicIndex]}`;
      case 'Harmonic Minor':
        const harmonicIndex = SCALES[selectedScale].indexOf(interval);
        return `${formatNoteSymbol(note)} - ${HARMONIC_MINOR_MODES[harmonicIndex]}`;
      case 'Harmonic Major':
        const harmonicMajorIndex = SCALES[selectedScale].indexOf(interval);
        return `${formatNoteSymbol(note)} - ${HARMONIC_MAJOR_MODES[harmonicMajorIndex]}`;
      case 'Pentatonic':
        const pentatonicIndex = SCALES[selectedScale].indexOf(interval);
        const modeName = PENTATONIC_MODES[pentatonicIndex];
        return modeName ? `${formatNoteSymbol(note)} - ${modeName}` : formatNoteSymbol(note);
      case 'Blues':
        return formatNoteSymbol(note);
      default:
        return formatNoteSymbol(note);
    }
  };

  // Update the scale change handler to not set accidentals for Chromatic
  const handleScaleChange = (newScale) => {
    if (newScale === 'None') {
      onRootChange(null);
      onScaleRootChange(null);
      onScaleChange(newScale);
      setShowNodes(false);
      onInfiniteModeChange?.(false);
    } else {
      const needsKey = newScale !== 'Chromatic';
      const rootToUse = needsKey 
        ? (selectedRoot || 'G')  // Use G as default if no root selected
        : null;
      
      onRootChange(rootToUse);
      onScaleRootChange(null);
      onScaleChange(newScale);
      setShowNodes(true);
      
      const shouldBeInfinite = newScale === 'Major' && !selectedRoot && rootToUse === null;
      onInfiniteModeChange?.(shouldBeInfinite);
    }
  };

  const getNodeTextIcon = () => {
    switch (nodeTextMode) {
      case NODE_TEXT_MODES.HIDE_ALL:
        return faFont;
      case NODE_TEXT_MODES.SHOW_ROOTS:
        return faFont;
      case NODE_TEXT_MODES.SHOW_ALL:
      default:
        return faFont;
    }
  };

  const getNodeTextLabel = () => {
    switch (nodeTextMode) {
      case NODE_TEXT_MODES.HIDE_ALL:
        return 'Hidden';
      case NODE_TEXT_MODES.SHOW_ALL:
      default:
        return 'Visible';
    }
  };

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
        <div style={controlPanelGroup}>
          <div style={instrumentControlsStyle}>
            <select
              value={`${currentPreset.instrument}|${currentPreset.strings}`}
              onChange={onInstrumentChange}
              style={selectStyle}
            >
              {INSTRUMENT_PRESETS.map(preset => (
                <option 
                  key={`${preset.instrument}-${preset.strings}`} 
                  value={`${preset.instrument}|${preset.strings}`}
                >
                  {getPresetLabel(preset.instrument, preset.strings)}
                </option>
              ))}
            </select>
            <button 
              style={selectStyle} 
              onClick={onInvertChange}
            >
              <FontAwesomeIcon icon={faLineHeight} />
            </button>
          </div>
        </div>

        <div style={controlPanelGroup}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <select 
                value={selectedScale}
                onChange={(e) => handleScaleChange(e.target.value)}
                style={{ ...selectStyle, flex: selectedScale === 'None' || selectedScale === 'Chromatic' ? 1 : 2 }}
              >
                {selectedScale === 'None' && <option value="None">Select Scale</option>}
                {Object.keys(SCALES).map(scale => (
                  scale !== 'None' && (
                    <option key={scale} value={scale}>
                      {scale}
                    </option>
                  )
                ))}
              </select>

              {selectedScale !== 'None' && selectedScale !== 'Chromatic' && (
                <select 
                  value={selectedRoot || ''}
                  onChange={(e) => handleKeyChange(e.target.value)}
                  style={{ ...selectStyle, flex: 1 }}
                >
                  <option value="">None</option>
                  {getFormattedKeys().map(key => (
                    <option key={key.value} value={key.value}>
                      {key.display}
                    </option>
                  ))}
                </select>
              )}
            </div>

            {selectedScale !== 'None' && selectedScale !== 'Chromatic' && (
              <select 
                style={selectStyle}
                defaultValue="full"
              >
                <option value="full">Full Scale</option>
              </select>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
        <div style={controlPanelGroup}>
          <button style={buttonStyle} onClick={onThemeChange}>
            <FontAwesomeIcon icon={faMoon} /> {isDarkMode ? 'Light Theme' : 'Dark Theme'}
          </button>
                    <select 
            value={colorMode} 
            onChange={(e) => onColorModeChange(e.target.value)}
            style={selectStyle}
          >
            <option value="Default">Default Color</option>
            <option value="Rainbow">Rainbow</option>
            <option value="Piano">Piano</option>
          </select>
          <button style={buttonStyle} onClick={() => {
            const modes = Object.values(NODE_TEXT_MODES);
            const currentIndex = modes.indexOf(nodeTextMode);
            const nextIndex = (currentIndex + 1) % modes.length;
            onNodeTextModeChange(modes[nextIndex]);
          }}>
            <FontAwesomeIcon icon={getNodeTextIcon()} /> {getNodeTextLabel()}
          </button>
          <button style={buttonStyle} onClick={onExport}>
            <FontAwesomeIcon icon={faFileExport} /> Export
          </button>
          <button style={buttonStyle} onClick={onShowBoundsChange}>
            {showBounds ? 'Hide Bounds' : 'Show Bounds'}
          </button>

          {previewNote && (
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center', 
              gap: '10px' 
            }}>
              <PreviewNode 
                note={previewNote} 
                theme={theme} 
                color={noteColors[previewNote]}
                colorMode={colorMode}
              />
              {colorMode === 'Default' && (
                <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                  <select
                    value={noteColors[previewNote] || 'default'}
                    onChange={(e) => onNoteColorChange(
                      previewNote,
                      e.target.value === 'default' ? null : e.target.value
                    )}
                    style={{
                      ...selectStyle,
                      flex: 1,
                      backgroundColor: noteColors[previewNote] || theme.nodeColor,
                      color: theme.diagramBg,
                    }}
                  >
                    <option value="default">Default</option>
                    {NOTE_COLORS.rainbow.map((color, index) => (
                      <option 
                        key={color} 
                        value={color}
                        style={{ 
                          backgroundColor: color,
                          color: '#FFFFFF'
                        }}
                      >
                        Color {index + 1}
                      </option>
                    ))}
                  </select>
                  <input
                    type="color"
                    value={noteColors[previewNote] || '#ffffff'}
                    onChange={(e) => onNoteColorChange(previewNote, e.target.value)}
                    style={{ width: '50px' }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Theory; 